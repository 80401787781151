import { createAsyncThunk } from '@reduxjs/toolkit';
import { withErrorHandling } from 'store/wrappers';
import adserver from 'services/adserver';
import {
  REDUCER_KEY,
  ENTITY_CAMPAIGN,
  ENTITY_LINEITEM,
  ENTITY_CREATIVE,
  ENTITY_BUDGET,
  OPERATION_CREATE,
  OPERATION_UPDATE,
  OPERATION_DELETE,
} from './history.consts';

export type OperationProps = {
  campaignId: number;
  diff: string;
  operation: number;
  subId: number;
  subType: number;
  timestamp: number;
  user: {
    first_name: string;
    id: number;
    last_name: string;
    username: string;
  };
};

export const getHistoryOfCampaign = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async (campaignId: number) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/history`,
      headers: {},
    });

    if (!('data' in response)) return [];

    const changes = response.data.map((change: OperationProps) => {
      const {
        timestamp,
        operation: operationId,
        campaignId,
        subId: entityId,
        subType,
        diff,
        user,
      } = change;
      const date = new Date(timestamp * 1000);
      let entityType, operationType;

      switch (subType) {
        case 1:
          entityType = ENTITY_CAMPAIGN;
          break;
        case 2:
          entityType = ENTITY_LINEITEM;
          break;
        case 3:
          entityType = ENTITY_CREATIVE;
          break;
        case 4:
          entityType = ENTITY_BUDGET;
          break;
        default:
          break;
      }

      switch (operationId) {
        case 1:
          operationType = OPERATION_CREATE;
          break;
        case 2:
          operationType = OPERATION_UPDATE;
          break;
        case 3:
          operationType = OPERATION_DELETE;
          break;
        default:
          break;
      }

      const id = `${entityType}:${operationType}::${date.toISOString()}`;
      const username =
        user.first_name !== '' && user.last_name !== ''
          ? `${user.first_name} ${user.last_name}`
          : user.username;

      return {
        id,
        date,
        operationType,
        entityType,
        entityId,
        campaignId,
        diff,
        user: {
          id: user.id,
          name: username,
        },
      };
    });
    return changes;
  }),
);
