export const IDLE = 'idle';
export const DATA_UNDEFINED = 'dataIsUndefined';
export const DATA_LOADING = 'dataIsLoading';
export const DATA_UPLOADING = 'dataIsUploading';
export const DATA_READY = 'dataIsReady';
export const NETWORK_ERROR = 'networkError';
export const REQUEST_REJECTED = 'requestRejected';
export const TOKEN_REJECTED = 'tokenRejected';
export const METHOD_NOT_FOUND = 'methodNotFound';
export const UNKNOWN_ERROR = 'unknownError';
export const NO_ERRORS = null;

export const DATA_ERROR = 'dataIsError';

export const XHR_IDLE = 'xhrIdle';
export const XHR_PENDING = 'xhrPending';
export const XHR_FULFILLED = 'xhrFulfilled';
export const XHR_REJECTED = 'xhrRejected';

export const ACTION_LIST = 'LIST';
export const ACTION_GET = 'GET';
export const ACTION_CREATE = 'CREATE';
export const ACTION_UPDATE = 'UPDATE';
