import * as React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';

const MobileApp = ({
  name,
  packagename,
}: {
  name?: string;
  packagename: string;
}): JSX.Element => (
  <Flex alignItems='center'>
    <Image
      w='2em'
      h='2em'
      background='graphite.400'
      borderRadius='base'
      mr={2}
      src={`https://panel.mobigate.pl/assets/apps/${packagename}.png`}
    />
    <Box>
      <Text
        whiteSpace='nowrap'
        maxWidth='20ch'
        overflow='hidden'
        textOverflow='ellipsis'
      >
        {name || 'Undefined app'}
      </Text>
      <Text
        fontSize='xs'
        whiteSpace='nowrap'
        maxWidth='20ch'
        overflow='hidden'
        textOverflow='ellipsis'
      >
        {packagename}
      </Text>
    </Box>
  </Flex>
);

export default MobileApp;
