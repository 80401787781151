import React from 'react';
import { useAppSelector } from 'hooks';
import { Grid } from '@chakra-ui/react';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';
import { create } from 'store/creatives';
import {
  selectCreativeTemplates,
  CreativeTemplateType,
  CreativeTemplateFieldsetType,
} from 'store/consts';
import { withCurrentCampaignId } from 'hoc';
import { Form, SubmitButton, ResetButton, PermissionsGate } from 'components';
import { FormSkeleton } from 'components/Skeletons';
import { FormLayout } from 'layouts';
import { TemplateTabs } from './CreativeForm.TemplateTabs';
import { TemplateFieldset } from './CreativeForm.TemplateFieldset';

type CreativeFormDataType = {
  campaignId: number;
  templateId: number;
  parameters: Record<string, any>;
};

type SelectedCreativeTemplateFormDataType = CreativeTemplateType & {
  index: number;
  defaultValues: CreativeFormDataType;
};

const CreativeFormCreate = ({
  campaignId,
  onSubmit,
}: {
  campaignId: number;
  onSubmit?: () => void;
}): JSX.Element => {
  /**
   * tablica wszystkich szablonów kreacji
   */
  const creativeTemplates: CreativeTemplateType[] = useAppSelector(
    selectCreativeTemplates,
  );
  /**
   * bieżąco wybrany/aktywny szablon
   */
  const [
    selectedCreativeTemplateFormData,
    setSelectedCreativeTemplateFormData,
  ] = React.useState<SelectedCreativeTemplateFormDataType | undefined>(
    undefined,
  );
  /**
   * ustawia creativeTemplate o danym indeksie jako selectedCreativeTemplate
   * oraz dodaje klucze kampanii i szablonu do defaultValues formularza - wymagane przez endpointy api
   * @param index
   */
  function setSelectedCreativeTemplateByIndex(index: number): void {
    if (index < 0 || index > creativeTemplates.length || !campaignId) return;
    const selectedCreativeTemplate = creativeTemplates[index];
    setSelectedCreativeTemplateFormData({
      ...selectedCreativeTemplate,
      index,
      defaultValues: {
        ...selectedCreativeTemplate.defaultValues,
        templateId: selectedCreativeTemplate.id,
        campaignId,
      },
    });
  }
  /**
   * ustawia pierwszy szablon z tablicy creativeTemplates jako aktywny
   * powinno wywołać się tylko raz, przy inicjalizacji komponentu i pobraniu kompletu danych
   */
  React.useEffect(() => {
    if (
      !creativeTemplates ||
      !creativeTemplates.length ||
      selectedCreativeTemplateFormData
    )
      return;

    setSelectedCreativeTemplateByIndex(0);
  }, [creativeTemplates]);
  /**
   * przy braku danych wyświetlamy placeholder
   * TODO: wyświetlenie rzeczywistego placeholdera tylko dla statusu ładowania i dodanie opcji obsługi wyświetlania błędów
   */
  if (!campaignId || !creativeTemplates || !selectedCreativeTemplateFormData)
    return <FormSkeleton />;

  return (
    <Form
      key={selectedCreativeTemplateFormData.id}
      onSubmit={onSubmit}
      defaultValues={selectedCreativeTemplateFormData.defaultValues}
      schema={selectedCreativeTemplateFormData.validationSchema}
      permissionsToEdit={[CAN_EDIT_CAMPAIGNS]}
      dispatchMethod={create}
      messageOnSuccess='Creative was successfully created'
      context={{ campaignId }}
    >
      <FormLayout>
        <FormLayout.Heading>Create creative</FormLayout.Heading>
        <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
          <FormLayout.Actions>
            <SubmitButton />
            <ResetButton />
          </FormLayout.Actions>
        </PermissionsGate>
        <FormLayout.Status>
          <TemplateTabs
            selectedIndex={selectedCreativeTemplateFormData.index}
            templates={creativeTemplates}
            onChange={setSelectedCreativeTemplateByIndex}
          />
        </FormLayout.Status>
        <FormLayout.Body>
          <Grid gap={6}>
            {selectedCreativeTemplateFormData?.fieldsets.map(
              (fieldset: CreativeTemplateFieldsetType) => (
                <TemplateFieldset key={fieldset.id} {...fieldset} />
              ),
            )}
          </Grid>
        </FormLayout.Body>
      </FormLayout>
    </Form>
  );
};

CreativeFormCreate.displayName = 'CreativeForm.Create';

const CreativeFormCreateForCurrentCampaignForm = withCurrentCampaignId(
  CreativeFormCreate,
);
export { CreativeFormCreate, CreativeFormCreateForCurrentCampaignForm };
