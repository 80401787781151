export default {
  baseStyle: {
    borderRadius: 'full',
    py: '1',
    px: '3',
    textAlign: 'center',
    width: '20',
  },
  sizes: {
    sm: {
      fontSize: '14px',
    },
    md: {
      fontSize: '16px',
    },
  },
  variants: {
    undefined: {
      width: '24 !important',
      color: 'graphite.400',
      background: 'graphite.100',
    },
    active: {
      color: 'status.active.text',
      background: 'status.active.bg',
    },
    inactive: {
      color: 'status.inactive.text',
      background: 'status.inactive.bg',
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'active',
  },
};
