/* eslint-disable no-unreachable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { withErrorHandling } from 'store/wrappers';
import { selectCurrentCampaign } from 'store/campaigns';
import { selectCurrentLineitem } from 'store/lineitems';
import { fetchHandler } from '../statistics.helpers';
// eslint-disable-next-line no-unused-vars
import adserver from 'services/adserver';
import {
  NAME,
  ALL_ENTITIES,
  ENTITY_CAMPAIGN,
  ENTITY_LINEITEM,
  ENTITY_PACKAGENAME,
  ENTITY_WEBSITE,
  ENTITY_OS,
  ENTITY_BIDFLOOR,
  STAT_IMPRESSIONS,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  ENTITY_CITY,
  ENTITY_COORDINATE,
  ENTITY_LAT,
  ENTITY_LONG,
  API_COORDINATE_INDICATORS,
  ENTITY_EXCHANGE,
  ENTITY_CREATIVE,
} from '../statistics.consts';

// wspólny handler do pobierania statystyk dla bieżącego lineitema
// zawęża zakres czasowy pobieranych statystyk do czasu emisji
const currentLineitemStatsHandler = async (query, thunkAPI) => {
  const state = thunkAPI.getState();
  const lineitem = selectCurrentLineitem(state);

  return await fetchHandler({
    entityType: ENTITY_LINEITEM,
    start: lineitem.general.timing.startDate,
    end: lineitem.general.timing.endDate,
    ...query,
    entities: {
      [ENTITY_LINEITEM]: lineitem.id,
      ...query.entities,
    },
  });
};

// wspólny handler do pobierania statystyk dla wydawców i bieżącego lineitema
const publisherInCurrentLineitemHandler = async (
  entityType,
  params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
  thunkAPI,
) => {
  return await currentLineitemStatsHandler(
    {
      entityType,
      params: params,
      entities: {
        [entityType]: ALL_ENTITIES,
      },
    },
    thunkAPI,
  );
};

export const fetchTotalStatsForLineitemsOfCurrentCampaign = createAsyncThunk(
  NAME + '/TotalStatsForLineitemsOfCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
        [ENTITY_LINEITEM]: ALL_ENTITIES,
      },
      start: campaign.startDate,
      end: campaign.endDate,
      entityType: ENTITY_LINEITEM,
    });
  }),
);

export const fetchTotalStatsForCurrentLineitem = createAsyncThunk(
  NAME + '/TotalForCurrentLineitem',
  withErrorHandling(async (_, thunkAPI) =>
    currentLineitemStatsHandler({}, thunkAPI),
  ),
);

export const fetchMonthlyStatsForCurrentLineitem = createAsyncThunk(
  NAME + '/MonthlyForCurrentLineitem',
  withErrorHandling(async (_, thunkAPI) =>
    currentLineitemStatsHandler(
      {
        timeunit: TIMEUNIT_MONTH,
      },
      thunkAPI,
    ),
  ),
);

export const fetchWeeklyStatsForCurrentLineitem = createAsyncThunk(
  NAME + '/WeeklyForCurrentLineitem',
  withErrorHandling(async (_, thunkAPI) =>
    currentLineitemStatsHandler(
      {
        timeunit: TIMEUNIT_WEEK,
      },
      thunkAPI,
    ),
  ),
);

export const fetchDailyStatsForCurrentLineitem = createAsyncThunk(
  NAME + '/DailyForCurrentLineitem',
  withErrorHandling(async (_, thunkAPI) =>
    currentLineitemStatsHandler(
      {
        timeunit: TIMEUNIT_DAY,
      },
      thunkAPI,
    ),
  ),
);

export const fetchHourlyStatsForCurrentLineitem = createAsyncThunk(
  NAME + '/HourlyForCurrentLineitem',
  withErrorHandling(async (_, thunkAPI) =>
    currentLineitemStatsHandler(
      {
        timeunit: TIMEUNIT_HOUR,
      },
      thunkAPI,
    ),
  ),
);

export const fetchTotalStatsForAppsInCurrentLineitem = createAsyncThunk(
  NAME + '/TotalStatsForAppsInCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentLineitemHandler(ENTITY_PACKAGENAME, params, thunkAPI),
  ),
);

export const fetchTotalStatsForWebsitesInCurrentLineitem = createAsyncThunk(
  NAME + '/TotalStatsForWebsitesInCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentLineitemHandler(ENTITY_WEBSITE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForOperatingSystemsOfCurrentLineitem = createAsyncThunk(
  NAME + '/TotalStatsForOperatingSystemsOfCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentLineitemHandler(ENTITY_OS, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCitiesOfCurrentLineitem = createAsyncThunk(
  NAME + '/fetchTotalStatsForCitiesOfCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentLineitemHandler(ENTITY_CITY, params, thunkAPI),
  ),
);

export const fetchTotalStatsForExchangesOfCurrentLineitem = createAsyncThunk(
  NAME + '/fetchTotalStatsForExchangesOfCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentLineitemHandler(ENTITY_EXCHANGE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCreativesOfCurrentLineitem = createAsyncThunk(
  NAME + '/fetchTotalStatsForCreativesOfCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentLineitemHandler(ENTITY_CREATIVE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCoordinatesOfCurrentLineitem = createAsyncThunk(
  NAME + '/fetchTotalStatsForCoordinatesOfCurrentLineitem',
  withErrorHandling(async (params, thunkAPI) =>
    currentLineitemStatsHandler(
      {
        entities: {
          [ENTITY_LAT]: ALL_ENTITIES,
          [ENTITY_LONG]: ALL_ENTITIES,
        },
        entityType: ENTITY_COORDINATE,
        indicators: API_COORDINATE_INDICATORS,
      },
      thunkAPI,
    ),
  ),
);

export const fetchBidfloorsForLineitem = createAsyncThunk(
  NAME + '/BidfloorsForLineitem',
  withErrorHandling(async lineitemId => {
    if (!lineitemId) throw new Error('brak lineitemId');
    const resp = await adserver({
      url: '/statistics',
      params: {
        unit: 'bidfloor',
        lineItemId: lineitemId,
      },
    });

    const result = resp?.data?.result;

    return [
      {
        ...result,
        entityType: ENTITY_BIDFLOOR,
        id: `${ENTITY_BIDFLOOR}:${ENTITY_LINEITEM}:${lineitemId}`,
        lineitemId: lineitemId,
      },
    ];
  }),
);
