import React, { useMemo } from 'react';
import { ControlledSelect } from 'components';
import { EVENT_PLAN_PACE_TYPES } from 'consts/lineitem';

const LineitemGeneralEventPlanPaceType = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const options = useMemo(
    () => EVENT_PLAN_PACE_TYPES.map(type => ({ id: type, name: type })),
    [EVENT_PLAN_PACE_TYPES],
  );
  return (
    <ControlledSelect
      name='general.eventPlan.paceType'
      label='Pace Type'
      options={options}
      {...props}
    />
  );
};

LineitemGeneralEventPlanPaceType.displayName =
  'Lineitem.general.eventPlan.paceType';

export { LineitemGeneralEventPlanPaceType };
