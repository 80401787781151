import * as React from 'react';
import { Flex, Box } from '@chakra-ui/react';
import { ShowCreativeLink } from 'components/Creative';
import { FiExternalLink } from 'react-icons/fi';
import { TextOverflow } from 'components';

const Creative = ({ name, id }: { name?: string; id: number }): JSX.Element => (
  <Flex alignItems='center'>
    <Box>
      <ShowCreativeLink
        creativeId={id}
        variant='link'
        rightIcon={<FiExternalLink />}
        target='_blank'
      >
        <TextOverflow maxWidth='16ch'>{name}</TextOverflow>
      </ShowCreativeLink>
    </Box>
  </Flex>
);

export default Creative;
