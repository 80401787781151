export default {
  baseStyle: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  variants: {
    unstyled: {
      gridGap: 4,
    },
    outline: {
      gridGap: 2,
    },
    ghost: {
      gridGap: 0,
    },
  },
  defaultProps: {
    variant: 'outline',
    colorScheme: 'graphite',
    size: 'md',
  },
};
