import { FlightParams } from './flights.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './flights.consts';
import { selectCurrentProposalId, fetchById } from 'store/proposals';
import {
  normalizeDateFromApi,
  normalizeDateForApi,
} from 'helpers/datetimeHelpers';

function normalizeApiReponse(data: FlightParams) {
  const startDate = normalizeDateFromApi(data.startDate);
  const endDate = normalizeDateFromApi(data.endDate);
  return { ...data, startDate, endDate };
}

function normalizeApiRequest(data: FlightParams) {
  const startDate = normalizeDateForApi(data.startDate);
  const endDate = normalizeDateForApi(data.endDate);
  return { ...data, startDate, endDate };
}

export const fetchAllFlightsOfProposal = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ proposalId }: { proposalId: number }) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/flights`,
      headers: {},
    });

    const surcharges = await adserver({
      url: `/proposal/${proposalId}/surcharges`,
      headers: {},
    });

    return response.data.map((flight: FlightParams) => {
      return {
        ...normalizeApiReponse({
          ...flight,
          proposalId,
        }),
        surcharges: surcharges?.data,
      };
    });
  }),
);

export const fetchAllFlightsForCurrentProposal = createAsyncThunk(
  REDUCER_KEY + '/fetchAllFlightsForCurrentProposal',
  withErrorHandling(async (_: any, thunkApi: any) => {
    const proposalId = selectCurrentProposalId(thunkApi.getState());
    const response = await adserver({
      url: `/proposal/${proposalId}/flights`,
      headers: {},
    });

    const surcharges = await adserver({
      url: `/proposal/${proposalId}/surcharges`,
      headers: {},
    });

    return response.data.map((flight: FlightParams) => {
      return {
        ...normalizeApiReponse({
          ...flight,
          proposalId,
        }),
        surcharges: surcharges?.data,
      };
    });
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (values: FlightParams, thunkAPI: any) => {
    const proposalId = selectCurrentProposalId(thunkAPI.getState());
    const response = await adserver({
      url: `/proposal/${proposalId}/flight`,
      method: 'POST',
      data: normalizeApiRequest(values),
      headers: {},
    });
    thunkAPI.dispatch(fetchById({ proposalId }));
    return normalizeApiReponse({ ...response.data, proposalId });
  }),
);

export const submit = createAsyncThunk(
  REDUCER_KEY + '/submit',
  withErrorHandling(async (data: FlightParams) => {
    const { id: flightId, proposalId } = data;
    const requsetdata = data;
    const response = await adserver({
      url: `/proposal/${proposalId}/flight/${flightId}`,
      method: 'PATCH',
      headers: {},
      data: normalizeApiRequest(requsetdata),
    });
    return normalizeApiReponse({ ...response.data, proposalId });
  }),
);

export const remove = createAsyncThunk(
  REDUCER_KEY + '/remove',
  withErrorHandling(
    async ({
      proposalId,
      flightId,
    }: {
      proposalId: number;
      flightId: number;
    }) => {
      await adserver({
        url: `/proposal/${proposalId}/flight/${flightId}`,
        method: 'DELETE',
        headers: {},
      });
      return flightId;
    },
  ),
);

export const fetchFlightFormRate = createAsyncThunk(
  REDUCER_KEY + '/fetchFormRate',
  withErrorHandling(
    async ({
      proposalId,
      formData,
    }: {
      proposalId: number;
      formData: FlightParams;
    }) => {
      const response = await adserver({
        url: `/proposal/${proposalId}/rate`,
        method: 'POST',
        data: formData,
        timeout: 10000,
        headers: {},
      });
      return response;
    },
  ),
);

export const removeAllFlights = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
