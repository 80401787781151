import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './proposals.consts';
import { ProposalParams, ProposalListParams } from './proposals.types';
import { selectCurrentCampaignId } from 'store/campaigns/campaigns.selectors';
import { selectCurrentProposal } from './proposals.selectors';
import { fetchAllFlightsOfProposal } from 'store/flights';

const fetchByIdHandler = async ({ proposalId }: { proposalId: number }) => {
  const proposal = await adserver({
    url: `/proposal/${proposalId}`,
    headers: {},
  });

  const surcharges = await adserver({
    url: `/proposal/${proposalId}/surcharges`,
    headers: {},
  });

  return { ...proposal.data, surcharges: surcharges?.data };
};

export const fetchAllProposals = createAsyncThunk(
  REDUCER_KEY + '/fetchAll',
  withErrorHandling(async () => {
    const response = await adserver({ url: '/proposals', headers: {} });
    return response.data.map((proposal: ProposalListParams) => proposal);
  }),
);

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ proposalId }: { proposalId: number }) => {
    const response = await adserver({
      url: `/proposal/${proposalId}`,
      headers: {},
    });
    const surcharges = await adserver({
      url: `/proposal/${proposalId}/surcharges`,
      headers: {},
    });

    return {
      ...response.data,
      surcharges: surcharges?.data,
    };
  }),
);

export const setCurrentProposal = createAsyncThunk(
  REDUCER_KEY + '/current',
  withErrorHandling(async (proposalId: number, thunkAPI: any) => {
    if (!proposalId) return;
    const proposal = await fetchByIdHandler({ proposalId });
    thunkAPI.dispatch(fetchAllFlightsOfProposal({ proposalId }));
    return proposal;
  }),
);

export const fetchProposalsOfCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchProposalsOfCampaign',
  withErrorHandling(async ({ campaignId }: { campaignId: number }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/proposals`,
      headers: {},
    });
    return response.data.map((deal: ProposalParams) => deal);
  }),
);

export const fetchAllProposalsForCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchAllProposalsForCurrentCampaign',
  withErrorHandling(async (_: any, thunkApi: any) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    const response = await adserver({
      url: `/campaign/${campaignId}/proposals`,
      headers: {},
    });

    return response.data.map((deal: ProposalParams) => deal);
  }),
);

export const create = createAsyncThunk(
  REDUCER_KEY + '/create',
  withErrorHandling(async (product: number, thunkApi: any) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    if (!campaignId) throw new Error('no campaignId');
    const response = await adserver({
      url: `/campaign/${campaignId}/proposal/`,
      method: 'POST',
      headers: {},
      data: product,
    });
    return response.data;
  }),
);

export const submit = createAsyncThunk(
  REDUCER_KEY + '/submit',
  withErrorHandling(async (data: ProposalParams) => {
    const { id: proposalId } = data;
    const requsetdata = data;
    const response = await adserver({
      url: `/proposal/${proposalId}/submit`,
      method: 'POST',
      headers: {},
      data: requsetdata,
    });
    return response.data;
  }),
);

export const accept = createAsyncThunk(
  REDUCER_KEY + '/accept',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/accept`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const rectify = createAsyncThunk(
  REDUCER_KEY + '/rectify',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/rectify`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const generateOrder = createAsyncThunk(
  REDUCER_KEY + '/generate_order',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/generate_order`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const renegotiate = createAsyncThunk(
  REDUCER_KEY + '/rectify',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/renegotiate`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const cancel = createAsyncThunk(
  REDUCER_KEY + '/cancel',
  withErrorHandling(async (proposalId: number) => {
    const response = await adserver({
      url: `/proposal/${proposalId}/cancel`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const removeAllProposals = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});

export const fetchProposalFormRate = createAsyncThunk(
  REDUCER_KEY + '/fetchFormRate',
  withErrorHandling(
    async ({
      proposalId,
      formData,
    }: {
      proposalId: number;
      formData: ProposalParams;
    }) => {
      const response = await adserver({
        url: `/proposal/${proposalId}/rate`,
        method: 'POST',
        data: formData,
        timeout: 10000,
        headers: {},
      });
      return response;
    },
  ),
);

export const updateProposalViewTS = createAsyncThunk(
  REDUCER_KEY + '/updateProposalViewTS',
  withErrorHandling(
    async (
      { proposalId, ts }: { proposalId: number; ts: number },
      thunkAPI: any,
    ) => {
      await adserver({
        url: `/proposal/${proposalId}/markread`,
        method: 'POST',
        headers: {},
        data: {
          lastViewedChangeTS: ts,
        },
      });
      const proposal = selectCurrentProposal(thunkAPI.getState());
      return {
        ...proposal,
        readStatus: {
          ...proposal?.readStatus,
          lastViewedChangeTS: ts,
        },
      };
    },
  ),
);

export const updateProposalChatViewTS = createAsyncThunk(
  REDUCER_KEY + '/updateProposalChatViewTS',
  withErrorHandling(
    async (
      { proposalId, ts }: { proposalId: number; ts: number },
      thunkAPI: any,
    ) => {
      await adserver({
        url: `/proposal/${proposalId}/markread`,
        method: 'POST',
        headers: {},
        data: {
          lastViewedMessageTS: ts,
        },
      });
      const proposal = selectCurrentProposal(thunkAPI.getState());
      return {
        ...proposal,
        readStatus: {
          ...proposal?.readStatus,
          lastViewedMessageTS: ts,
        },
      };
    },
  ),
);
