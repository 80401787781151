import * as React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useAppSelector } from 'hooks';

const OperatingSystemName = ({ id }: { id?: string }): JSX.Element => {
  const operatingSystems = useAppSelector(
    state => state.consts.operatingSystems,
  );

  const osName = operatingSystems?.find(
    ({ id: osId }: { id: string }) => osId === id,
  )?.name;

  return (
    <Flex alignItems='center'>
      <Text>{osName || id}</Text>
    </Flex>
  );
};

export default OperatingSystemName;
