import * as React from 'react';
import { RootState } from 'store';
import { withCurrentCampaignId } from 'hoc';
import { isBefore, startOfToday } from 'date-fns';
import { useAppSelector } from 'hooks';
import { selectCampaign, toggleStatus } from 'store/campaigns';
import { CampaignId } from './Campaign.types';
import { CampaignInterface } from './Campaign.schema';
import { StatusToggler } from 'components';

export const CampaignStatusToggler = ({
  campaignId,
}: CampaignId): JSX.Element | null => {
  const campaignSelector = (state: RootState): CampaignInterface =>
    selectCampaign(state, campaignId);
  const campaign = useAppSelector(campaignSelector);

  if (!campaign || isBefore(campaign.endDate, startOfToday())) return null;

  return (
    <StatusToggler selector={campaignSelector} toggleAction={toggleStatus} />
  );
};

export const CurrentCampaignStatusToggler = withCurrentCampaignId(
  CampaignStatusToggler,
);
