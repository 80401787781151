import * as React from 'react';
import { useAppSelector } from 'hooks';
import { Grid, Text } from '@chakra-ui/react';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';
import { update } from 'store/creatives';
import {
  selectCreativeTemplate,
  CreativeTemplateFieldsetType,
} from 'store/consts';
import { selectCreativeFormData } from 'store/creatives';
import { withCurrentCreativeId } from 'hoc';
import { Form, SubmitButton, ResetButton, PermissionsGate } from 'components';
import { FormSkeleton } from 'components/Skeletons';
import { FormLayout } from 'layouts';
import { TemplateFieldset } from './CreativeForm.TemplateFieldset';

const CreativeFormEdit = ({
  creativeId,
  onSubmit,
}: {
  creativeId: number;
  onSubmit?: () => void;
}): JSX.Element => {
  const creativeFormData = useAppSelector(state =>
    selectCreativeFormData(state, creativeId),
  );
  const creativeTemplate = useAppSelector(state =>
    selectCreativeTemplate(state, creativeFormData?.templateId),
  );

  if (!creativeFormData || !creativeTemplate) return <FormSkeleton />;

  return (
    <Form
      onSubmit={onSubmit}
      defaultValues={creativeFormData}
      schema={creativeTemplate.validationSchema}
      permissionsToEdit={[CAN_EDIT_CAMPAIGNS]}
      dispatchMethod={update}
      messageOnSuccess='Changes was succesfully saved'
      context={{ campaignId: creativeFormData.campaignId, creativeId }}
    >
      <FormLayout>
        <FormLayout.Heading>Edit creative</FormLayout.Heading>
        <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
          <FormLayout.Actions>
            <SubmitButton />
            <ResetButton />
          </FormLayout.Actions>
        </PermissionsGate>
        <FormLayout.Status>
          <Text fontWeight='bold'>{creativeTemplate.label}</Text>
        </FormLayout.Status>
        <FormLayout.Body>
          <Grid gap={6}>
            {creativeTemplate?.fieldsets.map(
              (fieldset: CreativeTemplateFieldsetType) => (
                <TemplateFieldset key={fieldset.id} {...fieldset} />
              ),
            )}
          </Grid>
        </FormLayout.Body>
      </FormLayout>
    </Form>
  );
};

CreativeFormEdit.displayName = 'CreativeForm.Edit';

const CreativeFormEditForCurrentCreativeForm = withCurrentCreativeId(
  CreativeFormEdit,
);
export { CreativeFormEdit, CreativeFormEditForCurrentCreativeForm };
