import * as React from 'react';
import {
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  ListItem,
  UnorderedList,
} from '@chakra-ui/react';
import { StatusIcon } from 'components';

const NONE = 'None';
const PENDING = 'Pending';
const APPROVED = 'Approved';
const DISAPPROVED = 'Disapproved';

const STATUS: Record<string, { color: string; message: string }> = {
  [NONE]: { color: 'gray.400', message: 'None' },
  [PENDING]: { color: 'blue.400', message: 'Pending' },
  [APPROVED]: { color: 'green.400', message: 'Approved' },
  [DISAPPROVED]: { color: 'red.400', message: 'Disapproved' },
};

export type CreativeGoogleApprovalStatusProps = {
  status?: string;
  reasons?: Array<string>;
};

export const CreativeGoogleApprovalStatus = ({
  status = NONE,
  reasons,
}: CreativeGoogleApprovalStatusProps): JSX.Element | null => {
  const { color, message } = STATUS[status];

  if (status === PENDING || status === APPROVED)
    return <StatusIcon color={color} message={message} />;

  if (status === NONE) {
    reasons = [
      'Creative is not configured to be emitted on Google Authorized Buyers',
      'None of the creative lineitems have started yet',
    ];
  }

  return (
    <Popover trigger='hover' placement='top'>
      <PopoverTrigger>
        <Box>
          <StatusIcon color={color} message={message} isUnderlined />
        </Box>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverBody>
          <UnorderedList spacing={2}>
            {reasons?.map((item, index) => (
              <ListItem key={index}>{item}</ListItem>
            ))}
          </UnorderedList>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
