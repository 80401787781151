import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Checkbox,
  CheckboxGroup as ChakraCheckboxGroup,
  useStyleConfig,
  useFormControl,
} from '@chakra-ui/react';
import { convertValues } from 'utils/convertValues';

const CheckboxGroup = ({
  values,
  checkedIds: checkedIdsFromProps,
  valueType,
  onChange,
  ...props
}) => {
  const { disabled, ...chakraInputProps } = useFormControl(props);
  const styles = useStyleConfig('CheckboxGroup', props);
  const checkboxes = useMemo(
    () =>
      values.map(({ name, id, desc, ...props }) => (
        <Checkbox {...props} key={id} value={id.toString()}>
          {name}
          {desc && <Box as='small'>{desc}</Box>}
        </Checkbox>
      )),
    [values],
  );
  const checkedIds = useMemo(
    () => checkedIdsFromProps.map(id => id.toString()),
    [checkedIdsFromProps],
  );

  function handleChange(values) {
    if (typeof onChange === 'function')
      onChange(convertValues(valueType, values));
  }

  return (
    <ChakraCheckboxGroup
      {...chakraInputProps}
      value={checkedIds}
      isDisabled={disabled}
      onChange={handleChange}
    >
      <Box __css={styles}>{checkboxes}</Box>
    </ChakraCheckboxGroup>
  );
};

CheckboxGroup.propTypes = {
  valueType: PropTypes.oneOf(['string', 'int', 'float', 'bool']).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
      desc: PropTypes.string,
    }),
  ).isRequired,
  checkedIds: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  ).isRequired,
  onChange: PropTypes.func,
};

CheckboxGroup.defaultProps = {
  values: [],
  checkedIds: [],
  valueType: 'string',
};

CheckboxGroup.displayName = 'Checkbox.Group';

export default CheckboxGroup;
