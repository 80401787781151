import { baseStyle, variants } from './checkbox';

const sizes = {
  sm: {
    label: {
      fontSize: 'xs',
    },
  },
  md: {
    label: {
      fontSize: 'sm',
    },
  },
};

const defaultProps = {
  variant: 'outline',
  colorScheme: 'graphite',
  size: 'md',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
