import React from 'react';
import Table from 'layouts/Table';
import { Text } from '@chakra-ui/react';
import { ProductParams, removeSelectedProduct } from 'store/products';
import { ShowProductModalInfo } from 'components/Product/Product.ModalInfo';
import { MarketAdType } from 'components/Market';
import { fomratNumberSpace } from 'helpers';
import DeleteButton from 'components/DeleteButton';
import { CAN_EDIT_CAMPAIGNS } from 'consts/permissions';

export function ProductItem({
  product,
}: {
  product: ProductParams;
  withoutSelect?: boolean;
}): JSX.Element {
  return (
    <Table.Row key={product.id}>
      <Table.Cell>
        <ShowProductModalInfo
          text={product.generalValue.name}
          product={product}
        />
      </Table.Cell>
      <Table.Cell>
        <Text as='span'>{product.publisher?.name}</Text>
      </Table.Cell>
      <Table.Cell>
        <MarketAdType type={product?.generalValue?.adType} />
      </Table.Cell>
      <Table.Cell>
        <Text as='span'>{product.generalValue?.pricingModel}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text as='span'>{fomratNumberSpace(product.timedValue.price)} zł</Text>
      </Table.Cell>
      <Table.Cell>
        <DeleteButton
          permissions={[CAN_EDIT_CAMPAIGNS]}
          action={removeSelectedProduct(product.id)}
          entityName='Produkt'
        />
      </Table.Cell>
    </Table.Row>
  );
}
