import { createSlice } from '@reduxjs/toolkit';
import { NO_CURRENT_ENTITY } from 'consts/store';
import { NAME } from './lineitems.consts';
import adapter from './lineitems.adapter';
import {
  fetchAllLineitemsForCurrentCampaign,
  fetchAllLineitemsForCampaign,
  fetchById,
  create,
  update,
  remove,
  setCurrent,
  toggleStatus,
  updateBudget,
  fetchBudget,
} from './lineitems.actions';

const slice = createSlice({
  name: NAME,
  initialState: {
    ...adapter.getInitialState(),
    current: NO_CURRENT_ENTITY,
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchAllLineitemsForCampaign.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(fetchAllLineitemsForCurrentCampaign.fulfilled, (state, action) =>
        adapter.upsertMany(state, action.payload),
      )
      .addCase(fetchById.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(create.fulfilled, (state, action) =>
        adapter.addOne(state, action.payload),
      )
      .addCase(update.fulfilled, (state, action) =>
        adapter.upsertOne(state, action.payload),
      )
      .addCase(remove.fulfilled, (state, action) =>
        adapter.removeOne(state, action.payload),
      )
      .addCase(fetchBudget.fulfilled, (state, action) =>
        adapter.updateOne(state, action.payload),
      )
      .addCase(updateBudget.fulfilled, (state, action) =>
        adapter.updateOne(state, action.payload),
      )
      .addCase(setCurrent.pending, (state, action) => {
        state.current = action.meta.arg || NO_CURRENT_ENTITY;
      })
      .addCase(setCurrent.fulfilled, (state, action) => {
        if (action.payload) adapter.upsertOne(state, action.payload);
      })
      .addCase(toggleStatus.fulfilled, (state, action) => {
        adapter.upsertOne(state, action.payload);
      })
      .addCase(NAME + '/removeAll', state => adapter.removeAll(state));
  },
});

export const { reducer } = slice;
