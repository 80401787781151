import React from 'react';
import { ControlledInputText } from 'components';

const LineitemGeneralName = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputText name='general.name' label='Lineitem Name' {...props} />
);

LineitemGeneralName.displayName = 'Lineitem.general.name';

export { LineitemGeneralName };
