// https://github.com/reduxjs/redux-toolkit/issues/520#issuecomment-699456947
export function withErrorHandling(payloadCreator) {
  return async (args, thunkAPI) => {
    try {
      return await payloadCreator(args, thunkAPI);
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.data || error);
    }
  };
}
