import * as React from 'react';
import { Grid, GridProps, Icon, Box } from '@chakra-ui/react';
import { BsFillSquareFill, BsFillInfoSquareFill } from 'react-icons/bs';

export type StatusIconProps = {
  color: string;
  message: string;
  isUnderlined?: boolean;
};

export const StatusIcon = ({
  color,
  message,
  isUnderlined,
  ...props
}: GridProps & StatusIconProps): JSX.Element => (
  <Grid
    gap={2}
    templateColumns='min-content 1fr'
    alignItems='center'
    {...props}
  >
    <Icon
      as={isUnderlined ? BsFillInfoSquareFill : BsFillSquareFill}
      fill={color}
    />
    <Box
      width='fit-content'
      borderBottomWidth='1px'
      borderBottomStyle='dashed'
      borderBottomColor={isUnderlined ? 'inherit' : 'transparent'}
      cursor={isUnderlined ? 'help' : 'inherit'}
    >
      {message}
    </Box>
  </Grid>
);
