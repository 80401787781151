import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './chats.consts';
import { MessageParams } from './chats.types';
import { normalizeDateFromApi } from 'helpers/datetimeHelpers';
import { selectChatMessagesById } from './chats.selectors';

function normalizeApiReponse(data: MessageParams) {
  const timestamp = normalizeDateFromApi(data.timestamp);
  return { ...data, timestamp };
}

export const fetchById = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ chatId }: { chatId: number }) => {
    const response = await adserver({
      url: `/proposal/${chatId}/messages`,
      headers: {},
    });
    const normalizedMessages = response.data.map((message: MessageParams) =>
      normalizeApiReponse(message),
    );
    return { id: chatId, messages: normalizedMessages };
  }),
);

export const sendMessage = createAsyncThunk(
  REDUCER_KEY + '/sendMessage',
  withErrorHandling(
    async (
      { chatId, message }: { chatId: number; message: string },
      thunkAPI: any,
    ) => {
      const response = await adserver({
        url: `/proposal/${chatId}/message`,
        method: 'POST',
        data: { message: message },
        headers: {},
      });

      const chatMessages = selectChatMessagesById(thunkAPI.getState(), chatId);

      return {
        id: chatId,
        messages: [...chatMessages, normalizeApiReponse(response.data)],
      };
    },
  ),
);

export const removeAllChats = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
