export const REDUCER_KEY = 'history';

export const ENTITY_CAMPAIGN = 'campaign';
export const ENTITY_LINEITEM = 'lineitem';
export const ENTITY_CREATIVE = 'creative';
export const ENTITY_BUDGET = 'budget';

export const OPERATION_CREATE = 'create';
export const OPERATION_UPDATE = 'update';
export const OPERATION_DELETE = 'delete';
