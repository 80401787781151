import React from 'react';
import PropTypes from 'prop-types';
import { Tag, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { OPTION_SHAPE } from './Select.consts';

const SelectedTag = React.forwardRef(
  ({ id, name, onRemove, ...props }, ref) => {
    return (
      <Tag size='sm' ref={ref} {...props}>
        <TagLabel>{name}</TagLabel>
        {onRemove && (
          <TagCloseButton
            onClick={e => {
              e.stopPropagation();
              onRemove({ id, name });
            }}
          />
        )}
      </Tag>
    );
  },
);

SelectedTag.displayName = 'Select.Tag';

SelectedTag.propTypes = {
  ...OPTION_SHAPE,
  onRemove: PropTypes.func,
};

export default SelectedTag;
