import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Radio,
  RadioGroup as ChakraRadioGroup,
  useStyleConfig,
} from '@chakra-ui/react';
import { convertValue } from 'utils/convertValues';

const RadioGroup = ({
  values,
  checkedId: checkedIdFromProps,
  valueType,
  onChange,
  ...props
}) => {
  const styles = useStyleConfig('CheckboxGroup', props);
  const checkboxes = useMemo(
    () =>
      values.map(({ name, id }) => (
        <Radio key={id} value={id.toString()}>
          {name}
        </Radio>
      )),
    [values],
  );

  const checkedId = useMemo(() => checkedIdFromProps?.toString(), [
    checkedIdFromProps,
  ]);

  function handleChange(value) {
    if (typeof onChange === 'function')
      onChange(convertValue(valueType, value));
  }

  return (
    <ChakraRadioGroup value={checkedId} onChange={handleChange} {...props}>
      <Box __css={styles}>{checkboxes}</Box>
    </ChakraRadioGroup>
  );
};

RadioGroup.propTypes = {
  onChange: PropTypes.func,
  valueType: PropTypes.oneOf(['string', 'int', 'float', 'bool']).isRequired,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]).isRequired,
    }),
  ).isRequired,
  checkedId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

RadioGroup.defaultProps = {
  valueType: 'string',
  values: [],
};

export default RadioGroup;
