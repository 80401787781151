import { mode } from '@chakra-ui/theme-tools';

export default {
  baseStyle: props => ({
    label: {
      lineHeight: 1.2,
      whiteSpace: 'nowrap',
      maxWidth: '100%',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      borderStyle: 'dashed',
      borderColor: mode('graphite.100', 'graphite.400')(props),
    },
    number: {
      whiteSpace: 'nowrap',
      lineHeight: 1.2,
    },
    helpText: {
      marginBottom: 0,
    },
  }),
  sizes: {
    sm: {
      label: {
        fontSize: 'xs',
      },
      number: {
        fontSize: 'md',
      },
    },
    md: {
      label: {
        fontSize: 'sm',
      },
      number: {
        fontSize: 'lg',
      },
    },
    lg: {
      number: {
        fontSize: '3xl',
      },
    },
    xl: {
      number: {
        fontSize: '4xl',
      },
    },
  },
};
