import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  useStyles,
  useFormControl,
} from '@chakra-ui/react';
import { FiChevronDown, FiChevronUp, FiX } from 'react-icons/fi';

/**
 * rozwijane pole w formularzu
 */
const SelectField = React.forwardRef(
  (
    {
      isOpen,
      isCleareable,
      isDisabled,
      hasSelectedOption,
      onClick,
      onClear,
      children,
      ...props
    },
    ref,
  ) => {
    const styles = useStyles();
    const input = useFormControl(props);

    const handleClear = e => {
      /**
       * propagacja eventu skutkowałaby wywołaniem kliku na całe pole
       * czyli klik na przycisk czyszczący otwierałby/zamykał opcje
       */
      e.stopPropagation();
      onClear();
    };
    return (
      <Flex
        {...input}
        sx={styles.field}
        tabIndex='0'
        {...props}
        ref={ref}
        disabled={isDisabled}
        onClick={!isDisabled ? onClick : undefined}
      >
        <Box flexGrow={1}>{children}</Box>
        {isCleareable && (
          <Tooltip
            label='Clear selected option'
            hasArrow
            placement='top'
            isDisabled={!hasSelectedOption}
          >
            <span>
              <IconButton
                icon={<FiX />}
                size='xs'
                variant='ghost'
                isDisabled={!hasSelectedOption}
                onClick={!isDisabled ? handleClear : undefined}
              />
            </span>
          </Tooltip>
        )}
        <Box __css={styles.separator} />
        <Icon as={isOpen ? FiChevronUp : FiChevronDown} __css={styles.icon} />
      </Flex>
    );
  },
);

SelectField.displayName = 'Select.Field';

SelectField.propTypes = {
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isCleareable: PropTypes.bool,
  hasSelectedOption: PropTypes.bool,
  onClick: PropTypes.func,
  onClear: PropTypes.func,
  children: PropTypes.node,
};

export default SelectField;
