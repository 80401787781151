import { MultiOptionField, SingleField } from 'components/ModalInfo.Fields';
import { useAppSelector } from 'hooks';
import * as React from 'react';

const ProductCategoryInfo = ({
  value,
  gridColumn,
  ...props
}: {
  value: number[] | undefined;
  gridColumn: any;
  [key: string]: any;
}): JSX.Element => {
  const productcategories = useAppSelector(state => state.consts.categories);

  const elements = React.useMemo(() => {
    if (productcategories.length === 0) {
      return [];
    }
    return productcategories
      .filter(({ id }: { id: number }) => value.includes(id))
      .map(({ name }: { name: string }) => name);
  }, [productcategories, value]);

  if (value === undefined || value.length === 0) {
    return (
      <SingleField
        header='Kategorie'
        value='Brak'
        gridColumn={gridColumn}
        {...props}
      />
    );
  }

  return (
    <MultiOptionField
      header='Kategorie'
      elements={elements}
      gridColumn={gridColumn}
      {...props}
    />
  );
};

ProductCategoryInfo.displayName = 'Product.categoryInfo';

export { ProductCategoryInfo };
