import * as React from 'react';
import { HistoryEvent } from 'store/history';
import {
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  useBoolean,
} from '@chakra-ui/react';
import { RootState } from 'store';
import { ENTITY_CREATIVE, ENTITY_LINEITEM } from 'store/history';
import { useAppSelector } from 'hooks';
import { ShowCreativeLink } from 'components/Creative';
import { ShowLineitemLink } from 'components/Lineitem';
import { selectCreative } from 'store/creatives';
import { selectLineitem } from 'store/lineitems';
import { OPERATION_TYPES } from './History.consts';
import { FieldChange } from './History.FieldChange';
import { FiExternalLink } from 'react-icons/fi';

/**
 * Operation: pojedyncza operacja CRUD; w jej ramach istnieją zmiany, czyli
 */
export const Operation = ({
  diff,
  date,
  user,
  operationType,
  entityType,
  entityId,
}: HistoryEvent): JSX.Element => {
  const [showChanges, setShowChanges] = useBoolean(false);
  const entityName = useAppSelector((state: RootState) => {
    switch (entityType) {
      case ENTITY_CREATIVE: {
        const creative = selectCreative(state, entityId);
        if (!creative) return 'creative';
        return (
          <ShowCreativeLink
            creativeId={entityId}
            variant='link'
            rightIcon={<FiExternalLink />}
          >
            creative {creative.name}
          </ShowCreativeLink>
        );
      }
      case ENTITY_LINEITEM: {
        const lineitem = selectLineitem(state, entityId);
        if (!lineitem) return 'lineitem';
        return (
          <ShowLineitemLink
            lineitemId={entityId}
            variant='link'
            rightIcon={<FiExternalLink />}
          >
            lineitem {lineitem.general.name}
          </ShowLineitemLink>
        );
      }
      default:
        return entityType;
    }
  });

  return (
    <>
      <Tr>
        <Td width='1%'>{OPERATION_TYPES[operationType]}</Td>
        <Td>{entityName}</Td>
        <Td>{user.name}</Td>
        <Td>{date.toUTCString()}</Td>
        <Td isNumeric>
          <Button size='sm' onClick={setShowChanges.toggle}>
            {showChanges ? 'Hide details' : 'Show details'}
          </Button>
        </Td>
      </Tr>
      {showChanges && (
        <Tr>
          <Td colSpan={5}>
            <TableContainer width='100%'>
              <Table size='sm'>
                <Thead>
                  <Tr>
                    <Th>Operation type</Th>
                    <Th>field</Th>
                    <Th>old value</Th>
                    <Th>new value</Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {diff.added?.map(change => (
                    <FieldChange
                      key={change.field}
                      type='added'
                      entityType={entityType}
                      {...change}
                    />
                  ))}
                  {diff.changed?.map(change => (
                    <FieldChange
                      key={change.field}
                      type='changed'
                      entityType={entityType}
                      {...change}
                    />
                  ))}
                  {diff.removed?.map(change => (
                    <FieldChange
                      key={change.field}
                      type='removed'
                      entityType={entityType}
                      {...change}
                    />
                  ))}
                </Tbody>
              </Table>
            </TableContainer>
          </Td>
        </Tr>
      )}
    </>
  );
};
