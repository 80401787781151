import { createSlice } from '@reduxjs/toolkit';
import { initialState, NAME } from './settings.consts';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {
    toggleDarkMode(state) {
      state.darkMode = !state.darkMode;
    },
    toggleSidebarCollapse(state) {
      state.sidebarIsCollapased = !state.sidebarIsCollapased;
    },
  },
});

export const { reducer } = slice;
export const { toggleDarkMode } = slice.actions;
