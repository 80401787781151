import { createAsyncThunk } from '@reduxjs/toolkit';
import { withErrorHandling } from 'store/wrappers';
import { selectCurrentCampaign, selectCampaignById } from 'store/campaigns';
import { selectCurrentCreative } from 'store/creatives';
import { fetchHandler } from '../statistics.helpers';
import {
  NAME,
  ALL_ENTITIES,
  ENTITY_CAMPAIGN,
  ENTITY_CREATIVE,
  ENTITY_PACKAGENAME,
  ENTITY_WEBSITE,
  ENTITY_OS,
  ENTITY_CITY,
  STAT_IMPRESSIONS,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  ENTITY_EXCHANGE,
  ENTITY_COORDINATE,
} from '../statistics.consts';

// wspólny handler do pobierania statystyk dla bieżącej kreacji
// zawęża zakres czasowy pobieranych statystyk do czasu kampanii, do której należy kreacja
const currentCreativeStatsHandler = async (query, thunkAPI) => {
  const state = thunkAPI.getState();
  const creative = selectCurrentCreative(state);
  const campaign = selectCampaignById(state, creative.campaignId);

  return await fetchHandler({
    entityType: ENTITY_CREATIVE,
    start: campaign.startDate,
    end: campaign.endDate,
    ...query,
    entities: {
      [ENTITY_CREATIVE]: creative.id,
      ...query.entities,
    },
  });
};

// wspólny handler do pobierania statystyk dla wydawców i bieżącej kreacji
const publisherInCurrentCreativeHandler = async (
  entityType,
  params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
  thunkAPI,
) => {
  return await currentCreativeStatsHandler(
    {
      entityType,
      params: params,
      entities: {
        [entityType]: ALL_ENTITIES,
      },
    },
    thunkAPI,
  );
};

export const fetchTotalStatsForCreativesOfCurrentCampaign = createAsyncThunk(
  NAME + '/TotalStatsForCreativesOfCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
        [ENTITY_CREATIVE]: ALL_ENTITIES,
      },
      start: campaign.startDate,
      end: campaign.endDate,
      entityType: ENTITY_CREATIVE,
    });
  }),
);

export const fetchTotalStatsForCurrentCreative = createAsyncThunk(
  NAME + '/TotalForCurrentCreative',
  withErrorHandling(async (_, thunkAPI) =>
    currentCreativeStatsHandler({}, thunkAPI),
  ),
);

export const fetchMonthlyStatsForCurrentCreative = createAsyncThunk(
  NAME + '/MonthlyForCurrentCreative',
  withErrorHandling(async (_, thunkAPI) =>
    currentCreativeStatsHandler(
      {
        timeunit: TIMEUNIT_MONTH,
      },
      thunkAPI,
    ),
  ),
);

export const fetchWeeklyStatsForCurrentCreative = createAsyncThunk(
  NAME + '/WeeklyForCurrentCreative',
  withErrorHandling(async (_, thunkAPI) =>
    currentCreativeStatsHandler(
      {
        timeunit: TIMEUNIT_WEEK,
      },
      thunkAPI,
    ),
  ),
);

export const fetchDailyStatsForCurrentCreative = createAsyncThunk(
  NAME + '/DailyForCurrentCreative',
  withErrorHandling(async (_, thunkAPI) =>
    currentCreativeStatsHandler(
      {
        timeunit: TIMEUNIT_DAY,
      },
      thunkAPI,
    ),
  ),
);

export const fetchHourlyStatsForCurrentCreative = createAsyncThunk(
  NAME + '/HourlyForCurrentCreative',
  withErrorHandling(async (_, thunkAPI) =>
    currentCreativeStatsHandler(
      {
        timeunit: TIMEUNIT_HOUR,
      },
      thunkAPI,
    ),
  ),
);

export const fetchTotalStatsForAppsInCurrentCreative = createAsyncThunk(
  NAME + '/TotalStatsForAppsInCurrentCreative',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentCreativeHandler(ENTITY_PACKAGENAME, params, thunkAPI),
  ),
);

export const fetchTotalStatsForWebsitesInCurrentCreative = createAsyncThunk(
  NAME + '/TotalStatsForWebsitesInCurrentCreative',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentCreativeHandler(ENTITY_WEBSITE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForOperatingSystemsOfCurrentCreative = createAsyncThunk(
  NAME + '/TotalStatsForOperatingSystemsOfCurrentCreative',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentCreativeHandler(ENTITY_OS, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCitiesOfCurrentCreative = createAsyncThunk(
  NAME + '/fetchTotalStatsForCitiesOfCurrentCreative',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentCreativeHandler(ENTITY_CITY, params, thunkAPI),
  ),
);

export const fetchTotalStatsForCoordinatesOfCurrentCreative = createAsyncThunk(
  NAME + '/fetchTotalStatsForCoordinatesOfCurrentCreative',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentCreativeHandler(ENTITY_COORDINATE, params, thunkAPI),
  ),
);

export const fetchTotalStatsForExchangesOfCurrentCreative = createAsyncThunk(
  NAME + '/fetchTotalStatsForExchangesOfCurrentCreative',
  withErrorHandling(async (params, thunkAPI) =>
    publisherInCurrentCreativeHandler(ENTITY_EXCHANGE, params, thunkAPI),
  ),
);
