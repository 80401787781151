import { createAsyncThunk } from '@reduxjs/toolkit';
import { withErrorHandling } from 'store/wrappers';
import { selectActiveCampaigns, selectCurrentCampaign } from 'store/campaigns';
import { fetchHandler } from './statistics.helpers';
import {
  NAME,
  ALL_ENTITIES,
  ENTITY_CAMPAIGN,
  ENTITY_PACKAGENAME,
  ENTITY_WEBSITE,
  ENTITY_OS,
  ENTITY_CITY,
  STAT_IMPRESSIONS,
  API_COORDINATE_INDICATORS,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  ENTITY_COORDINATE,
  ENTITY_LAT,
  ENTITY_LONG,
  ENTITY_EXCHANGE,
} from './statistics.consts';

export const fetchTotalStatsForAllCampaigns = createAsyncThunk(
  NAME + '/TotalForAllCampaigns',
  withErrorHandling(async () =>
    fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: ALL_ENTITIES,
      },
      entityType: ENTITY_CAMPAIGN,
    }),
  ),
);

export const fetchTotalStatsForActiveCampaigns = createAsyncThunk(
  NAME + '/TotalForActiveCampaigns',
  withErrorHandling(async (_, thunkAPI) => {
    const campaigns = selectActiveCampaigns(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaigns.map(campaign => campaign.id),
      },
      entityType: ENTITY_CAMPAIGN,
    });
  }),
);

export const fetchTotalStatsForCurrentCampaign = createAsyncThunk(
  NAME + '/TotalForCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
      },
      entityType: ENTITY_CAMPAIGN,
      start: campaign.startDate,
      end: campaign.endDate,
    });
  }),
);

export const fetchMonthlyStatsForCurrentCampaign = createAsyncThunk(
  NAME + '/MonthlyForCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
      },
      entityType: ENTITY_CAMPAIGN,
      start: campaign.startDate,
      end: campaign.endDate,
      timeunit: TIMEUNIT_MONTH,
    });
  }),
);

export const fetchWeeklyStatsForCurrentCampaign = createAsyncThunk(
  NAME + '/WeeklyForCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
      },
      entityType: ENTITY_CAMPAIGN,
      start: campaign.startDate,
      end: campaign.endDate,
      timeunit: TIMEUNIT_WEEK,
    });
  }),
);

export const fetchDailyStatsForCurrentCampaign = createAsyncThunk(
  NAME + '/DailyForCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
      },
      entityType: ENTITY_CAMPAIGN,
      start: campaign.startDate,
      end: campaign.endDate,
      timeunit: TIMEUNIT_DAY,
    });
  }),
);

export const fetchHourlyStatsForCurrentCampaign = createAsyncThunk(
  NAME + '/HourlyForCurrentCampaign',
  withErrorHandling(async (_, thunkAPI) => {
    const campaign = selectCurrentCampaign(thunkAPI.getState());

    return await fetchHandler({
      entities: {
        [ENTITY_CAMPAIGN]: campaign.id,
      },
      entityType: ENTITY_CAMPAIGN,
      start: campaign.startDate,
      end: campaign.endDate,
      timeunit: TIMEUNIT_HOUR,
    });
  }),
);

export const fetchTotalStatsForAppsInCurrentCampaign = createAsyncThunk(
  NAME + '/TotalStatsForAppsInCurrentCampaign',
  withErrorHandling(
    async (
      params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
      thunkAPI,
    ) => {
      const campaign = selectCurrentCampaign(thunkAPI.getState());
      return await fetchHandler({
        entities: {
          [ENTITY_CAMPAIGN]: campaign.id,
          [ENTITY_PACKAGENAME]: ALL_ENTITIES,
        },
        start: campaign.startDate,
        end: campaign.endDate,
        entityType: ENTITY_PACKAGENAME,
        params: params,
      });
    },
  ),
);

export const fetchTotalStatsForWebsitesInCurrentCampaign = createAsyncThunk(
  NAME + '/TotalStatsForWebsitesInCurrentCampaign',
  withErrorHandling(
    async (
      params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
      thunkAPI,
    ) => {
      const campaign = selectCurrentCampaign(thunkAPI.getState());

      return await fetchHandler({
        entities: {
          [ENTITY_CAMPAIGN]: campaign.id,
          [ENTITY_WEBSITE]: ALL_ENTITIES,
        },
        start: campaign.startDate,
        end: campaign.endDate,
        entityType: ENTITY_WEBSITE,
        params: params,
      });
    },
  ),
);

export const fetchTotalStatsForOperatingSystemsOfCurrentCampaign = createAsyncThunk(
  NAME + '/TotalStatsForOperatingSystemsOfCurrentCampaign',
  withErrorHandling(
    async (
      params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
      thunkAPI,
    ) => {
      const campaign = selectCurrentCampaign(thunkAPI.getState());

      return await fetchHandler({
        entities: {
          [ENTITY_CAMPAIGN]: campaign.id,
          [ENTITY_OS]: ALL_ENTITIES,
        },
        start: campaign.startDate,
        end: campaign.endDate,
        entityType: ENTITY_OS,
        params: params,
      });
    },
  ),
);

export const fetchTotalStatsForCitiesOfCurrentCampaign = createAsyncThunk(
  NAME + '/fetchTotalStatsForCitiesOfCurrentCampaign',
  withErrorHandling(
    async (
      params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
      thunkAPI,
    ) => {
      const campaign = selectCurrentCampaign(thunkAPI.getState());

      return await fetchHandler({
        entities: {
          [ENTITY_CAMPAIGN]: campaign.id,
          [ENTITY_CITY]: ALL_ENTITIES,
        },
        start: campaign.startDate,
        end: campaign.endDate,
        entityType: ENTITY_CITY,
        params: params,
      });
    },
  ),
);

export const fetchTotalStatsForCoordinatesOfCurrentCampaign = createAsyncThunk(
  NAME + '/fetchTotalStatsForCoordinatesOfCurrentCampaign',
  withErrorHandling(
    async (
      params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
      thunkAPI,
    ) => {
      const campaign = selectCurrentCampaign(thunkAPI.getState());

      return await fetchHandler({
        entities: {
          [ENTITY_CAMPAIGN]: campaign.id,
          [ENTITY_LAT]: ALL_ENTITIES,
          [ENTITY_LONG]: ALL_ENTITIES,
        },
        start: campaign.startDate,
        end: campaign.endDate,
        entityType: ENTITY_COORDINATE,
        indicators: API_COORDINATE_INDICATORS,
        params: params,
      });
    },
  ),
);

export const fetchTotalStatsForExchangesOfCurrentCampaign = createAsyncThunk(
  NAME + '/fetchTotalStatsForExchangesOfCurrentCampaign',
  withErrorHandling(
    async (
      params = { order: `d-${STAT_IMPRESSIONS}`, limit: 10 },
      thunkAPI,
    ) => {
      const campaign = selectCurrentCampaign(thunkAPI.getState());

      return await fetchHandler({
        entities: {
          [ENTITY_CAMPAIGN]: campaign.id,
          [ENTITY_EXCHANGE]: ALL_ENTITIES,
        },
        start: campaign.startDate,
        end: campaign.endDate,
        entityType: ENTITY_EXCHANGE,
        params: params,
      });
    },
  ),
);

export const removeAllStatistics = () => ({ type: NAME + '/removeAll' });
