import { createSelector } from 'reselect';
import adapter from './creatives.adapter';
import { NAME } from './creatives.consts';
import {
  selectCurrentCampaignId,
  selectById as selectCampaignById,
} from 'store/campaigns';

const selectors = adapter.getSelectors(state => state[NAME]);

export const { selectIds, selectEntities, selectTotal, selectById } = selectors;
export const selectCreative = (state, creativeId) =>
  selectById(state, creativeId);
export const selectCurrentCreativeId = state => state[NAME].current;

export const selectAll = createSelector(
  state => selectors.selectAll(state),
  entities => entities || [],
);

export const selectCurrentCreative = createSelector(
  state => selectCurrentCreativeId(state),
  state => selectAll(state),
  (currentCreativeId, allCreatives) =>
    allCreatives.find(({ id }) => id === currentCreativeId) || {},
);

export const selectCreativesOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAll,
  (currentCampaignId, allCreatives) => {
    return (
      allCreatives.filter(
        ({ campaignId }) => campaignId === currentCampaignId,
      ) || []
    );
  },
);

export const selectNumberOfCreativesOfCurrentCampaign = createSelector(
  selectCreativesOfCurrentCampaign,
  creatives => creatives.length,
);

export const selectCreativesOfCampaign = (state, campaignId) => {
  const allCreatives = selectAll(state);
  const creativesOfCampaign = allCreatives?.filter(
    creative => creative.campaignId === campaignId,
  );
  return creativesOfCampaign;
};

export const selectVideoCreativesOfCurrentCampaign = createSelector(
  selectCreativesOfCurrentCampaign,
  creatives => creatives.filter(({ templateId }) => templateId === 3),
);

export const selectNonVideoCreativesOfCurrentCampaign = createSelector(
  selectCreativesOfCurrentCampaign,
  creatives => creatives.filter(({ templateId }) => templateId !== 3),
);

export const selectNumberOfVideoCreativesOfCurrentCampaign = createSelector(
  selectVideoCreativesOfCurrentCampaign,
  creatives => creatives.length,
);

export const selectNumberOfNonVideoCreativesOfCurrentCampaign = createSelector(
  selectNonVideoCreativesOfCurrentCampaign,
  creatives => creatives.length,
);

export const selectVideoCreativesOfCampaign = (state, campaignId) => {
  const allCreatives = selectAll(state);
  const creativesOfCampaign = allCreatives?.filter(
    creative => creative.campaignId === campaignId,
  );
  const videoCreativesOfCampaign = creativesOfCampaign?.filter(
    ({ templateId }) => templateId === 3,
  );
  return videoCreativesOfCampaign;
};

export const selectNonVideoCreativesOfCampaign = (state, campaignId) => {
  const allCreatives = selectAll(state);
  const creativesOfCampaign = allCreatives?.filter(
    creative => creative.campaignId === campaignId,
  );
  const videoCreativesOfCampaign = creativesOfCampaign?.filter(
    ({ templateId }) => templateId !== 3,
  );
  return videoCreativesOfCampaign;
};

export const selectCampaignOfGivenCreative = (state, creativeId) => {
  if (!creativeId) return null;

  const creative = selectById(state, creativeId);
  const campaign = selectCampaignById(state, creative.campaignId);
  return campaign;
};

export const selectCreativeFormData = (state, creativeId) => {
  if (!creativeId) return null;

  const { campaignId, templateId, parameters } = selectById(state, creativeId);

  if (!campaignId || !templateId || !parameters)
    throw new Error('invalid creative data');

  return { campaignId, templateId, creativeId, parameters };
};
