const baseStyle = {
  container: {
    border: 'none',
  },
  button: {
    fontWeight: 'bold',
  },
};

export default {
  baseStyle,
};
