import * as React from 'react';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';

const LineitemGeneralEventPlanEventType = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const eventTypes = useAppSelector(state => state.consts.eventTypes);

  const options = React.useMemo(() => {
    return eventTypes?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [eventTypes]);

  return (
    <ControlledSelect
      name='general.eventPlan.eventType'
      label='Event Type'
      options={options}
      {...props}
    />
  );
};

LineitemGeneralEventPlanEventType.displayName =
  'Lineitem.general.eventPlan.eventType';

export { LineitemGeneralEventPlanEventType };
