import * as React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useAppSelector } from 'hooks';
import userHasRequiredPermissions from 'hooks/useUserPermissions';
import { UrlParamsHandler } from './Router.UrlParamsHandler';
import { UserSessionHandler } from './Router.UserSessionHandler';
import { isUserLoggedIn } from 'store/user';

const NotFound = () => <div>404</div>;
const AccessDenied = () => <div>403</div>;

type PrivateRouteProps = {
  component: React.ElementType;
  permissions: string[];
};

const PrivateRoute = ({
  component: Component = NotFound,
  permissions = [],
  ...routeProps
}: PrivateRouteProps): JSX.Element => {
  const location = useLocation();
  const isLoggedIn = useAppSelector(isUserLoggedIn);
  const hasPermissions = userHasRequiredPermissions(permissions);

  if (!isLoggedIn) {
    return (
      <Route {...routeProps}>
        <Redirect to={{ pathname: '/login', state: { from: location } }} />
      </Route>
    );
  }

  if (!hasPermissions) {
    return (
      <Route {...routeProps}>
        <AccessDenied />
      </Route>
    );
  }

  return (
    <Route
      {...routeProps}
      render={componentProps => (
        <>
          <UrlParamsHandler />
          <UserSessionHandler />
          <Component {...componentProps} />
        </>
      )}
    />
  );
};

export default PrivateRoute;
