import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  getHistoryOfCampaign,
  selectFullHistoryOfCurrentCampaign,
} from 'store/history';
import { HistoryTable } from 'components';
import { withCurrentCampaignId } from 'hoc';
import { CampaignId } from './Campaign.types';

export const CampaignHistory = ({ campaignId }: CampaignId): JSX.Element => {
  const dispatch = useDispatch();
  const historyOfCurrentCampaign = useSelector(
    selectFullHistoryOfCurrentCampaign,
  );

  useEffect(() => {
    if (!campaignId) return;
    dispatch(getHistoryOfCampaign(campaignId));
  }, [campaignId]);

  return <HistoryTable events={historyOfCurrentCampaign} />;
};

export const CurrentCampaignHistory = withCurrentCampaignId(CampaignHistory);
