import React from 'react';
import Table from 'layouts/Table';
import { Text, Checkbox } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import {
  ProductParams,
  addSelectedProduct,
  removeSelectedProduct,
  selectSelectedIds,
} from 'store/products';
import { ShowProductModalInfo } from 'components/Product/Product.ModalInfo';
import { useAppSelector } from 'hooks';
import { MarketAdType } from 'components/Market';
import { fomratNumberSpace } from 'helpers';

export function MarketItem({
  product,
  withoutSelect,
}: {
  product: ProductParams;
  withoutSelect?: boolean;
}): JSX.Element {
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = React.useState(false);
  const handleOnChange = () => {
    setIsChecked(prev => !prev);
  };
  const isMounted = React.useRef(false);
  const sleceted = useAppSelector(selectSelectedIds);

  const isSelected = sleceted.includes(product.id);

  React.useEffect(() => {
    if (isMounted.current) {
      if (isChecked) {
        dispatch(addSelectedProduct(product));
      } else {
        dispatch(removeSelectedProduct(product.id));
      }
    } else {
      isMounted.current = true;
    }
  }, [isChecked]);

  return (
    <Table.Row key={product.id}>
      {!withoutSelect && (
        <Table.Cell>
          <Checkbox onChange={handleOnChange} isChecked={isSelected} />
        </Table.Cell>
      )}
      <Table.Cell>
        <ShowProductModalInfo
          text={product.generalValue.name}
          product={product}
        />
      </Table.Cell>
      <Table.Cell>
        <Text as='span'>{product.publisher?.name}</Text>
      </Table.Cell>
      <Table.Cell>
        <MarketAdType type={product?.generalValue?.adType} />
      </Table.Cell>
      <Table.Cell>
        <Text as='span'>{product.generalValue?.pricingModel}</Text>
      </Table.Cell>
      <Table.Cell>
        <Text as='span'>{fomratNumberSpace(product.timedValue.price)} zł</Text>
      </Table.Cell>
    </Table.Row>
  );
}
