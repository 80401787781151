import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, useFormControl } from '@chakra-ui/react';

const CheckboxBoolean = ({
  checkedLabel,
  uncheckedLabel,
  isChecked: isCheckedProps,
  onChange,
  ...props
}) => {
  const { disabled, readOnly, required, ...chakraInputProps } = useFormControl(
    props,
  );
  const [isCheckedState, setIsCheckedState] = useState(isCheckedProps);
  const label = isCheckedState ? checkedLabel : uncheckedLabel;

  function handleChange(event) {
    setIsCheckedState(event.target.checked);
    if (onChange) onChange(event.target.checked);
  }

  React.useEffect(() => {
    setIsCheckedState(isCheckedProps);
  }, [isCheckedProps]);

  return (
    <Checkbox
      {...chakraInputProps}
      isChecked={isCheckedState}
      isDisabled={disabled}
      isReadOnly={readOnly}
      isRequired={required}
      onChange={handleChange}
    >
      {label}
    </Checkbox>
  );
};

CheckboxBoolean.displayName = 'Checkbox.Boolean';

CheckboxBoolean.propTypes = {
  name: PropTypes.string.isRequired,
  checkedLabel: PropTypes.string.isRequired,
  uncheckedLabel: PropTypes.string.isRequired,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
};

export default CheckboxBoolean;
