export const REDUCER_KEY = 'baskets';

export const TRANSACTIONS_OPEN = 'TRANSACTIONS_OPEN';
export const TRANSACTIONS_FINALIZED = 'TRANSACTIONS_FINALIZED';

export const PUBLISHER_GENERATE_ORDER_REQUESTED =
  'PUBLISHER_GENERATE_ORDER_REQUESTED';
export const BUYER_SIGNATURE_REQUESTED = 'BUYER_SIGNATURE_REQUESTED';
export const PUBLISHER_CORRECTION_REQUESTED = 'PUBLISHER_CORRECTION_REQUESTED';
export const PUBLISHER_ACCEPTANCE_REQUESTED = 'PUBLISHER_ACCEPTANCE_REQUESTED';
export const BUYER_CORRECTION_REQUESTED = 'BUYER_CORRECTION_REQUESTED';

export const FINALIZED = 'FINALIZED';

export const ORDER = [
  PUBLISHER_GENERATE_ORDER_REQUESTED,
  BUYER_SIGNATURE_REQUESTED,
  PUBLISHER_CORRECTION_REQUESTED,
  PUBLISHER_ACCEPTANCE_REQUESTED,
  BUYER_CORRECTION_REQUESTED,
  FINALIZED,
];
