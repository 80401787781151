import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectUserPermissions } from 'store/user';

const useUserPermissions = (requiredPermissions = []) => {
  const userPermissions = useSelector(selectUserPermissions);
  const [hasRequiredPermissions, setHasRequiredPermissions] = useState(false);

  useEffect(() => {
    if (!requiredPermissions.length) {
      setHasRequiredPermissions(true);
      return;
    }

    requiredPermissions.every(key => {
      if (!userPermissions.includes(key)) {
        setHasRequiredPermissions(false);
        return false;
      }
      setHasRequiredPermissions(true);
      return true;
    });
  }, [userPermissions, requiredPermissions]);

  return hasRequiredPermissions;
};

export default useUserPermissions;
