import * as React from 'react';
import { StatLabel } from '@chakra-ui/react';
import { NAMES, DEFINITIONS } from 'store/statistics';
import { Abbr } from 'components';

type StatisticsNames = typeof NAMES;
type StatisticsDefinitions = typeof DEFINITIONS;

export type StatisticLabelType = {
  statisticKey: string;
};

export const StatisticLabel = (props: StatisticLabelType): JSX.Element => {
  const label =
    NAMES[props.statisticKey as keyof StatisticsNames] || props.statisticKey;

  if (props.statisticKey in DEFINITIONS) {
    const tooltip =
      DEFINITIONS[props.statisticKey as keyof StatisticsDefinitions];
    return (
      <StatLabel>
        <Abbr text={label.toString()} tooltip={tooltip} />
      </StatLabel>
    );
  }

  return <StatLabel>{label}</StatLabel>;
};
