import * as React from 'react';
import { useAppDispatch } from 'hooks';
import { ping } from 'store/user';

export const UserSessionHandler = (): null => {
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    async function pingApiService() {
      dispatch(ping());
    }
    pingApiService();
    const pingInterval = setInterval(pingApiService, 5000);
    return () => {
      clearInterval(pingInterval);
    };
  }, []);
  return null;
};
