import PropTypes from 'prop-types';

export const OPTION_SHAPE = {
  name: PropTypes.string.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export const SELECTFIELD_SHARED_PROPS = {
  options: PropTypes.arrayOf(PropTypes.shape(OPTION_SHAPE).isRequired)
    .isRequired,
  placeholder: PropTypes.string.isRequired,
  size: PropTypes.string,
  variant: PropTypes.string,
  isDisabled: PropTypes.bool,
  isCleareable: PropTypes.bool,
  isFilterable: PropTypes.bool,
  showUnfilteredResults: PropTypes.bool,
  onChange: PropTypes.func,
};
