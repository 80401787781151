import * as React from 'react';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import Card from 'layouts/Card';
import { formatStatisticValue } from 'helpers';
import { BsFillSquareFill, BsFillCircleFill } from 'react-icons/bs';

export type ChartTooltipProps = {
  active?: boolean;
  payload?: Array<{
    dataKey: string;
    color: string;
    value: string;
    name: string;
    type: string;
  }>;
  label?: string;
};

const ChartTooltipArrow = () => (
  <Box
    width='1rem'
    height='1rem'
    transform='rotate(45deg)'
    position='absolute'
    top='calc(100% - 0.5rem)'
    left='calc(50% - 0.5rem)'
    background='inherit'
  />
);

export const ChartTooltip = ({
  active,
  payload,
  label,
}: ChartTooltipProps): JSX.Element | null => {
  if (!active || !payload || payload.length === 0) return null;

  return (
    <Card
      size='sm'
      variant='default'
      minWidth='20ch'
      transform='translate(-50%,calc(-100% - 1em))'
      boxShadow='lg'
      zIndex='tooltip'
      position='relative'
    >
      <ChartTooltipArrow />
      <Heading size='sm' marginBottom={2}>
        {label}
      </Heading>
      <Stack>
        {payload?.map(({ dataKey, color, value, name, type }) => {
          const Icon = type === 'line' ? BsFillCircleFill : BsFillSquareFill;
          return (
            <Stat key={dataKey} size='md'>
              <StatNumber>{formatStatisticValue(dataKey, value)}</StatNumber>
              <StatLabel
                as={Flex}
                gridGap={2}
                alignContent='center'
                alignItems='center'
                lineHeight={1}
              >
                <Icon fill={color} fontSize='1em' /> {name}
              </StatLabel>
            </Stat>
          );
        })}
      </Stack>
    </Card>
  );
};
