import { ButtonStyles as Button } from './button';
import Switch from './switch';
import { TableStyles as Table } from './table';
import Card from './card';
import Popover from './popover';
import Checkbox from './checkbox';
import CheckboxGroup from './checkboxGroup';
import Radio from './radio';
import Input from './input';
import NumberInput from './numberInput';
import StatusBadge from './statusBadge';
import FormLabel from './formLabel';
import Select from './select';
import Tag from './tag';
import { TextStyles as Text } from './text';
import Stat from './stat';
import Fieldset from './fieldset';
import Skeleton from './skeleton';
import Modal from './modal';
import Tabs from './tabs';
import Abbr from './abbr';
import TextOverflow from './textOverflow';
import InputFile from './inputFile';
import Accordin from './accordin';
import { BreadcrumbStyles as Breadcrumb } from './breadcrumb';

export default {
  Breadcrumb,
  Button,
  Card,
  Switch,
  Table,
  Popover,
  Checkbox,
  CheckboxGroup,
  Radio,
  Input,
  StatusBadge,
  Fieldset,
  FormLabel,
  NumberInput,
  Select,
  Tag,
  Text,
  Stat,
  Skeleton,
  Modal,
  Tabs,
  Abbr,
  TextOverflow,
  InputFile,
  Accordin,
};
