import React, { useMemo } from 'react';
import { ControlledSelect } from 'components';
import { EVENT_PLAN_TYPES } from 'consts/lineitem';

const LineitemGeneralEventPlanEventPlanType = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const options = useMemo(
    () => EVENT_PLAN_TYPES.map(type => ({ id: type, name: type })),
    [EVENT_PLAN_TYPES],
  );
  return (
    <ControlledSelect
      name='general.eventPlan.eventPlanType'
      label='Plan Type'
      options={options}
      {...props}
    />
  );
};

LineitemGeneralEventPlanEventPlanType.displayName =
  'Lineitem.general.eventPlan.eventPlanType';

export { LineitemGeneralEventPlanEventPlanType };
