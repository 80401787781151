import React from 'react';
import { ControlledInputNumber } from 'components';

const LineitemGeneralDailyBudget = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputNumber
    name='general.eventPlan.dailyBudget'
    label='Daily budget'
    {...props}
  />
);
LineitemGeneralDailyBudget.displayName = 'Lineitem.general.dailyBudget';

export { LineitemGeneralDailyBudget };
