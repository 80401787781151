import { createSlice } from '@reduxjs/toolkit';
import { REDUCER_KEY } from './history.consts';
import { adapter } from './history.adapter';
import { getHistoryOfCampaign } from './history.actions';

const slice = createSlice({
  name: REDUCER_KEY,
  initialState: adapter.getInitialState(),
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getHistoryOfCampaign.fulfilled, (state, action) =>
      adapter.upsertMany(state, action.payload),
    );
  },
});

export const { reducer } = slice;
