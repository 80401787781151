import {
  DATA_LOADING,
  TOKEN_REJECTED,
  DATA_READY,
  REQUEST_REJECTED,
  METHOD_NOT_FOUND,
  UNKNOWN_ERROR,
  NO_ERRORS,
} from 'consts/xhr';

export const readyActionHandler = state => {
  state.status = DATA_READY;
  state.errors = NO_ERRORS;
};
export const pendingActionHandler = state => {
  state.status = DATA_LOADING;
};

export const rejectActionHandler = (state, action) => {
  if (!('name' in action.error) || action.error.name !== 'RejectWithValue') {
    state.status = UNKNOWN_ERROR;
    state.errors = action.error;
    return;
  }

  switch (action.payload.status) {
    case 400:
      state.status = REQUEST_REJECTED;
      state.errors =
        'message' in action.payload.data
          ? action.payload.data.message
          : action.payload.data; // TODO: do usunięcia, kiedy odpowiedzi z api będą ustandaryzowane
      return;
    case 403:
      state.status = REQUEST_REJECTED;
      state.errors = TOKEN_REJECTED;
      return;
    case 404:
      state.status = REQUEST_REJECTED;
      state.errors = METHOD_NOT_FOUND;
      return;
    default:
      state.status = UNKNOWN_ERROR;
      state.errors = action.payload.data;
  }
};
