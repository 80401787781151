import * as React from 'react';
import {
  Grid,
  GridItem,
  Skeleton,
  Heading,
  FormControl,
  FormLabel,
  Table,
  Thead,
  Th,
  Tr,
  StatProps,
  Stat,
  StatLabel,
  StatNumber,
} from '@chakra-ui/react';
import { FormLayout, Fieldset, EntityPageLayout } from 'layouts';

export const ButtonSkeleton = (): JSX.Element => (
  <Skeleton borderRadius='sm' width='15ch' height='2rem' />
);

export const FormSkeleton = (): JSX.Element => (
  <FormLayout>
    <Heading>
      <Skeleton />
    </Heading>
    <FormLayout.Actions>
      <ButtonSkeleton />
      <ButtonSkeleton />
    </FormLayout.Actions>
    <GridItem colSpan={2}>
      <FieldsetSkeleton />
    </GridItem>
  </FormLayout>
);

export const FieldsetSkeleton = (): JSX.Element => (
  <Grid as={Fieldset} gap={6} templateColumns='repeat(12,1fr)'>
    <GridItem colSpan={6}>
      <FormControlSkeleton />
    </GridItem>
    <GridItem colSpan={6}>
      <FormControlSkeleton />
    </GridItem>
    <GridItem colSpan={6}>
      <FormControlSkeleton />
    </GridItem>
    <GridItem colSpan={6}>
      <FormControlSkeleton />
    </GridItem>
  </Grid>
);

export const FormControlSkeleton = (): JSX.Element => (
  <FormControl>
    <FormLabel>
      <Skeleton w='15ch' />
    </FormLabel>
    <Skeleton height='2rem' />
  </FormControl>
);

export const TableSkeleton = (): JSX.Element => (
  <Grid gap={6}>
    <Table>
      <Thead>
        <Tr>
          <Th>
            <Skeleton height='1rem' />
          </Th>
          <Th>
            <Skeleton height='1rem' />
          </Th>
          <Th>
            <Skeleton height='1rem' />
          </Th>
          <Th>
            <Skeleton height='1rem' />
          </Th>
        </Tr>
      </Thead>
    </Table>
    <Skeleton height='3rem' />
    <Skeleton height='3rem' />
    <Skeleton height='3rem' />
  </Grid>
);

export const EntityPageSkeletor = (): JSX.Element => (
  <EntityPageLayout>
    <EntityPageLayout.Header>
      <Skeleton width='20ch' height='1em' />
    </EntityPageLayout.Header>
    <EntityPageLayout.Actions>
      <ButtonSkeleton />
      <ButtonSkeleton />
    </EntityPageLayout.Actions>
    <EntityPageLayout.Body>
      <Grid gap={6} templateColumns='repeat(12,1fr)'>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={12}>
          <Skeleton height='3rem' />
        </GridItem>
        <GridItem colSpan={12}>
          <Skeleton height='3rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={6}>
          <Skeleton height='2rem' />
        </GridItem>
        <GridItem colSpan={12}>
          <Skeleton height='3rem' />
        </GridItem>
        <GridItem colSpan={12}>
          <Skeleton height='3rem' />
        </GridItem>
      </Grid>
    </EntityPageLayout.Body>
  </EntityPageLayout>
);

export const StatSkeleton = (props: StatProps): JSX.Element => (
  <Stat minWidth={'20ch'} {...props}>
    <StatNumber mb={2}>
      <Skeleton />
    </StatNumber>
    <StatLabel width='50%'>
      <Skeleton />
    </StatLabel>
  </Stat>
);
