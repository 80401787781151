import { createSelector } from 'reselect';
import { pick } from 'lodash';
import {
  selectCurrentCreativeId,
  selectCreativesOfCurrentCampaign,
} from 'store/creatives';
import { selectCurrentCampaignId } from 'store/campaigns';
import {
  STATISTIC_KEYS,
  ENTITY_CAMPAIGN,
  ENTITY_CREATIVE,
  TIMEUNIT_TOTAL,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  selectAllCreatives,
  selectAllWebsites,
  selectAllOperatingsystems,
  selectAllCities,
  selectAllApps,
  selectAllExchanges,
  selectAllCoordinates,
} from 'store/statistics';

export const selectTotalStatsForAllCreatives = createSelector(
  selectAllCreatives,
  allCreatives =>
    allCreatives.filter(creative => creative.timeunit === TIMEUNIT_TOTAL),
);

export const selectMonthlyStatsForAllCreatives = createSelector(
  selectAllCreatives,
  allCreatives =>
    allCreatives.filter(creative => creative.timeunit === TIMEUNIT_MONTH),
);

export const selectWeeklyStatsForAllCreatives = createSelector(
  selectAllCreatives,
  allCreatives =>
    allCreatives.filter(creative => creative.timeunit === TIMEUNIT_WEEK),
);

export const selectDailyStatsForAllCreatives = createSelector(
  selectAllCreatives,
  allCreatives =>
    allCreatives.filter(creative => creative.timeunit === TIMEUNIT_DAY),
);

export const selectHourlyStatsForAllCreatives = createSelector(
  selectAllCreatives,
  allCreatives =>
    allCreatives.filter(creative => creative.timeunit === TIMEUNIT_HOUR),
);

export const selectTotalStatsForCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectTotalStatsForAllCreatives,
  (selectCurrentCreativeId, totalStatsForAllCreatives) =>
    totalStatsForAllCreatives.find(
      creative => creative[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || {},
);

export const selectMonthlyStatsForCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectMonthlyStatsForAllCreatives,
  (selectCurrentCreativeId, monthlyStatsForAllCreatives) =>
    monthlyStatsForAllCreatives.filter(
      creative => creative[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectWeeklyStatsForCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectWeeklyStatsForAllCreatives,
  (currentCreativeId, weeklyStatsForAllCreatives) =>
    weeklyStatsForAllCreatives.filter(
      creative => creative[ENTITY_CREATIVE] === currentCreativeId,
    ) || [],
);

export const selectLastWeekStatsForCurrentCreative = createSelector(
  selectWeeklyStatsForCurrentCreative,
  weeklyStatsForCurrentCreative =>
    weeklyStatsForCurrentCreative.slice(-7) || [],
);

export const selectDailyStatsForCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectDailyStatsForAllCreatives,
  (selectCurrentCreativeId, dailyStatsForAllCreatives) =>
    dailyStatsForAllCreatives.filter(
      creative => creative[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectHourlyStatsForCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectHourlyStatsForAllCreatives,
  (selectCurrentCreativeId, hourlyStatsForAllCreatives) =>
    hourlyStatsForAllCreatives.filter(
      creative => creative[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectTotalStatsForAppsInCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectAllApps,
  (selectCurrentCreativeId, allApps) =>
    allApps.filter(app => app[ENTITY_CREATIVE] === selectCurrentCreativeId) ||
    [],
);

export const selectTotalStatsForWebsitesInCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectAllWebsites,
  (selectCurrentCreativeId, allWebsites) =>
    allWebsites.filter(
      website => website[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectTotalStatsForOperatingsystemsInCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectAllOperatingsystems,
  (selectCurrentCreativeId, allOperatingsystems) =>
    allOperatingsystems.filter(
      os => os[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectTotalStatsForCitiesInCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectAllCities,
  (selectCurrentCreativeId, allCities) =>
    allCities.filter(
      city => city[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectTotalStatsForCoordinatesInCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectAllCoordinates,
  (selectCurrentCreativeId, allCoordinates) =>
    allCoordinates.filter(
      coordinate => coordinate[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectTotalStatsForExchengaesInCurrentCreative = createSelector(
  selectCurrentCreativeId,
  selectAllExchanges,
  (selectCurrentCreativeId, allExchanges) =>
    allExchanges.filter(
      exchange => exchange[ENTITY_CREATIVE] === selectCurrentCreativeId,
    ) || [],
);

export const selectTotalStatsForCreativesOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllCreatives,
  (currentCampaignId, allCreatives) =>
    allCreatives.filter(
      creative => creative[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectCreativesOfCurrentCampaignWithStats = createSelector(
  selectCreativesOfCurrentCampaign,
  selectTotalStatsForCreativesOfCurrentCampaign,
  (creatives, statsForCreatives) =>
    creatives.map(creative => {
      // zapobiega kolizji kluczy (np. id), wybierając tylko zdefiniowane w stałych klucze statystyk
      const statistics = pick(
        statsForCreatives.find(stat => stat[ENTITY_CREATIVE] === creative.id),
        STATISTIC_KEYS,
      );
      return {
        ...statistics,
        ...creative,
      };
    }) || [],
);
