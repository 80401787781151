import { Input, Textarea } from '@chakra-ui/react';
import { InputDate, InputFile, InputNumber } from 'components';
import { Select, SelectMultiple } from 'components/Select';
import { InputFileWithUpload } from 'components/InputFileWithUpload';
import { CheckboxBoolean, CheckboxGroup } from 'components/Checkbox';
import { withReactHookFormController } from 'hoc';
import RadioGroup from './Radio';
import { InputArrayWithReactHookForm } from './InputArray';
import { InputFileBasket } from 'components/Basket/formFields/Basket.upload';
import { InputDateRange } from 'components/InputDateRange';
import { InputCurrency } from 'components/InputCurrency';

export const ControlledInputText = withReactHookFormController(Input);
export const ControlledInputDate = withReactHookFormController(InputDate);
export const ControlledInputDateRange = withReactHookFormController(
  InputDateRange,
);
export const ControlledInputFile = withReactHookFormController(InputFile);
export const ControlledInputNumber = withReactHookFormController(InputNumber);
export const ControlledInputCurrency = withReactHookFormController(
  InputCurrency,
);

export const ControlledSelect = withReactHookFormController(
  Select,
  'selectedId',
);
export const ControlledSelectMultiple = withReactHookFormController(
  SelectMultiple,
  'selectedIds',
);

export const ControlledCheckboxBoolean = withReactHookFormController(
  CheckboxBoolean,
  'isChecked',
);

export const ControlledCheckboxGroup = withReactHookFormController(
  CheckboxGroup,
  'checkedIds',
);

export const ControlledRadioGroup = withReactHookFormController(
  RadioGroup,
  'checkedId',
);

export const ControlledTextarea = withReactHookFormController(Textarea);

export const ControlledInputArray = withReactHookFormController(
  InputArrayWithReactHookForm,
);

export const ControlledInputFileWithUpload = withReactHookFormController(
  InputFileWithUpload,
  'fileId',
);

export const ControlledInputFileBasket = withReactHookFormController(
  InputFileBasket,
  'fileId',
);
