import * as React from 'react';
import { reach } from 'yup';
import {
  Box,
  Flex,
  Tr,
  Td,
  Tag,
  TagProps,
  Alert,
  Tooltip,
} from '@chakra-ui/react';
import { RootState } from 'store';
import { selectCreative } from 'store/creatives';
import { ShowCreativeLink } from 'components/Creative';
import { ENTITY_LINEITEM } from 'store/history';
import { useAppSelector } from 'hooks';
import {
  CHANGE_TYPES,
  CHANGE_COLOR_SCHEME,
  ENTITY_SCHEMAS,
} from './History.consts';
import { FiExternalLink } from 'react-icons/fi';
import { TextOverflow } from 'components';

type FieldChangeProps = {
  type: 'added' | 'changed' | 'removed';
  entityType: 'campaign' | 'lineitem' | 'creative' | 'budget';
  field: string;
  old?: string | number;
  new?: string | number;
};

/**
 * Operacja na pojedynczym polu
 */
export const FieldChange = ({
  type: changeType,
  entityType,
  field: fieldName,
  old: oldValue,
  new: newValue,
}: FieldChangeProps): JSX.Element | null => {
  const schema = ENTITY_SCHEMAS[entityType];
  const changeStatus = CHANGE_TYPES[changeType] || changeType;

  // blacklistujemy wyświetlanie pojedynczych pól
  if (['campaign', 'campaignId', 'id'].includes(fieldName)) return null;

  // dla listy kreacji w lineitemie blokujemy wyświetlanie pola zawierającego creativeId
  // nazwa pola jest dynamiczna: creatives[ID].creativeId
  if (
    entityType === ENTITY_LINEITEM &&
    fieldName.startsWith('creatives') &&
    fieldName.endsWith('creativeId')
  )
    return null;

  if (!schema) {
    return (
      <Tr>
        <Td>{changeStatus}</Td>
        <Td>{fieldName}</Td>
        <Td>{Array.isArray(oldValue) ? oldValue.join(', ') : oldValue}</Td>
        <Td>{Array.isArray(newValue) ? newValue.join(', ') : newValue}</Td>
      </Tr>
    );
  }

  // jeśli pole fieldName nie istnieje w schema, YUP rzuci wyjątek
  // w takim przypadku ignorujemy pole i nie renderujemy niczego
  try {
    const { label, type: fieldType, meta = {} } = reach(
      schema,
      fieldName,
      {},
      {},
    ).describe();
    const { optionsSelector } = meta;

    return (
      <Tr>
        <Td>{changeStatus}</Td>
        <Td>
          <FieldName
            entityType={entityType}
            label={label}
            fieldName={fieldName}
          />
        </Td>
        <Td>
          {(changeType === 'changed' || changeType === 'removed') && (
            <FieldValue
              value={oldValue}
              type={fieldType}
              optionsSelector={optionsSelector}
              colorScheme='red'
            />
          )}
        </Td>
        <Td>
          <FieldValue
            value={newValue}
            type={fieldType}
            optionsSelector={optionsSelector}
            colorScheme={CHANGE_COLOR_SCHEME[changeType]}
          />
        </Td>
      </Tr>
    );
  } catch (error) {
    return (
      <Tr>
        <Td colSpan={4}>
          <Alert status='error'>{error?.toString()}</Alert>
        </Td>
      </Tr>
    );
  }
};

type FieldNameProps = {
  entityType: 'campaign' | 'lineitem' | 'creative' | 'budget';
  label: string;
  fieldName: string;
};
const FieldName = ({
  entityType,
  label,
  fieldName,
}: FieldNameProps): JSX.Element => {
  // dane kreacji w lineitemie są zwracane w postaci fieldName: creatives[id].field
  // poniższy kod pobiera id z stringa fieldName i za pomocą selectora generuje nazwę kreacji jako klikalny link
  const name = useAppSelector((state: RootState) => {
    const defaultName = label || fieldName;
    if (entityType !== ENTITY_LINEITEM || !fieldName.startsWith('creatives'))
      return defaultName;

    const matchedCreativeId = fieldName.match(
      /^creatives\[(?<creativeId>\d+)\]/,
    );

    if (!matchedCreativeId || !matchedCreativeId.groups?.creativeId)
      return 'Creative' + defaultName;

    const creativeId = Number(matchedCreativeId.groups.creativeId);
    const creative = selectCreative(state, creativeId);
    if (!creative) return 'Creative' + defaultName;

    return (
      <Box>
        <ShowCreativeLink
          creativeId={creativeId}
          variant='link'
          size='sm'
          rightIcon={<FiExternalLink />}
          marginRight={2}
        >
          Creative {creative.name}
        </ShowCreativeLink>
        {defaultName}
      </Box>
    );
  });

  return <Box>{name}</Box>;
};

function dummySelector() {
  return undefined;
}

const ValueNotAvailable = () => <small>n/a</small>;

type Options = Array<{ id: string | number; name: string }>;

type FieldValueProps = {
  type: string;
  value: string | string[] | number | number[] | undefined | null;
  optionsSelector?: (state: RootState) => Options;
};
const FieldValue = ({
  value,
  type,
  optionsSelector,
  ...props
}: FieldValueProps & TagProps): JSX.Element => {
  // jeśli pole otrzymuje selector i zwraca on dane, traktujemy value jako id jednej z opcji i wyświetlamy jej name
  const options = useAppSelector(optionsSelector || dummySelector);

  if (typeof value === 'undefined') return <ValueNotAvailable />;
  if (value === null) return <ValueNotAvailable />;
  if (type === 'string' && value === '') return <ValueNotAvailable />;

  if (type === 'date')
    return (
      <Tag {...props}>
        <TextOverflow maxWidth='15ch'>
          {new Date(Number(value) * 1000).toUTCString()}
        </TextOverflow>
      </Tag>
    );

  if (Array.isArray(value)) {
    if (value.length < 3) {
      return (
        <Flex gap={1}>
          {value.map(id => (
            <Tag key={id} {...props}>
              <TextOverflow maxWidth='15ch'>
                {findOptionNameById(id, options)}
              </TextOverflow>
            </Tag>
          ))}
        </Flex>
      );
    }
    const sliced = value.slice(0, 3);
    const children = value
      .map(id => findOptionNameById(id, options))
      .join(', ');
    return (
      <Tooltip hasArrow placement='bottom' label={children}>
        <Flex gap={1}>
          {sliced.map(id => (
            <Tag key={id} {...props}>
              <TextOverflow maxWidth='15ch'>
                {findOptionNameById(id, options)}
              </TextOverflow>
            </Tag>
          ))}
          <Box>...</Box>
        </Flex>
      </Tooltip>
    );
  }

  if (type === 'boolean') {
    return <Tag {...props}>{value ? 'true' : 'false'}</Tag>;
  }

  return (
    <Tag {...props}>
      <TextOverflow maxWidth='15ch'>
        {findOptionNameById(value, options)}
      </TextOverflow>
    </Tag>
  );
};

function findOptionNameById(id: string | number, options: Options | undefined) {
  if (!options) return id;

  const option = options.find(option => option.id === id);
  return option?.name || id;
}
