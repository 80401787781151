import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, useStyles } from '@chakra-ui/react';
import withFilteringInput from 'hooks/withFilteringInput';
import Option from './Select.Option';
import { OPTION_SHAPE } from './Select.consts';

/**
 * lista opcji
 */
const Options = ({ options, ...props }) => {
  const styles = useStyles();
  return (
    <Grid __css={styles.options} {...props}>
      {options.map(option => (
        <Option key={option.id} {...option}>
          {option.name}
        </Option>
      ))}
    </Grid>
  );
};
Options.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape(OPTION_SHAPE)),
};

/**
 * lista opcji wraz z inputem do ich filtrowania po nazwie
 */
const OptionsWithFilteringInput = withFilteringInput(Options, 'options');

/**
 * dropdown z listą opcji
 */
const Dropdown = React.forwardRef(
  ({ isOpen, isFilterable, showUnfilteredResults, options, ...props }, ref) => {
    const styles = useStyles();
    return (
      <Box
        {...props}
        ref={ref}
        outline='none'
        position='absolute'
        right={0}
        top='110%'
        width='max-content'
        minWidth='100%'
        zIndex='dropdown'
      >
        {isOpen && (
          <Grid __css={styles.dropdown}>
            {isFilterable ? (
              <Box padding={1}>
                <OptionsWithFilteringInput
                  size='xs'
                  showUnfilteredResults={showUnfilteredResults}
                  filteredKey='name'
                  array={options}
                  margin={-1}
                />
              </Box>
            ) : (
              <Options options={options} />
            )}
          </Grid>
        )}
      </Box>
    );
  },
);

Dropdown.displayName = 'Select.Dropdown';

Dropdown.propTypes = {
  isOpen: PropTypes.bool,
  isFilterable: PropTypes.bool,
  showUnfilteredResults: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape(OPTION_SHAPE)),
};

Dropdown.defaultProps = {
  isOpen: false,
  isFilterable: false,
  showUnfilteredResults: false,
  options: [],
};

export default Dropdown;
