import React from 'react';
import PropTypes from 'prop-types';
import { Box, StylesProvider, useMultiStyleConfig } from '@chakra-ui/react';

/**
 * pojemnik na zawartość oraz Style Provider
 */
const FieldContainer = ({ size, variant, children }) => {
  const styles = useMultiStyleConfig('Select', { size, variant });

  return (
    <StylesProvider value={styles}>
      <Box position='relative'>{children}</Box>
    </StylesProvider>
  );
};

FieldContainer.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node,
};

export default FieldContainer;
