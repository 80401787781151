import React from 'react';
import PropTypes from 'prop-types';
import { Flex, useStyles } from '@chakra-ui/react';
import Tag from './Select.Tag';
import { OPTION_SHAPE } from './Select.consts';

const Tags = ({ items, onRemoveItem, ...props }) => {
  const styles = useStyles();

  return (
    <Flex sx={styles.tags} onClick={e => e.stopPropagation()} {...props}>
      {items.map(({ id, name }) => (
        <Tag
          key={id}
          id={id}
          name={name}
          sx={styles.tag}
          onRemove={onRemoveItem}
        />
      ))}
    </Flex>
  );
};

Tags.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape(OPTION_SHAPE)).isRequired,
  onRemoveItem: PropTypes.func,
};

Tags.defaultProps = {
  items: [],
  onRemoveItem: () => {},
};

export default Tags;
