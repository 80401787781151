import React from 'react';
import { ButtonProps } from '@chakra-ui/react';
import { ModalForm, PermissionsGate } from 'components';
import { withCurrentCreativeId } from 'hoc';
import { CAN_EDIT_CAMPAIGNS, CAN_PREVIEW_CAMPAIGNS } from 'consts/permissions';
import { CreativeIdProps } from './Creative.types';
import { CreateCreativeLink, EditCreativeLink } from './Creative.Links';
import { FiEdit, FiEye, FiPlus } from 'react-icons/fi';
import { userHasRequiredPermissions } from 'hooks';
import {
  CreativeFormCreateForCurrentCampaignForm,
  CreativeFormEdit,
} from './CreativeForm';

export const CreateCreativeModalForm = ({
  campaignId,
  ...props
}: ButtonProps & { campaignId: number }): JSX.Element => (
  <PermissionsGate requiredPermissions={[CAN_EDIT_CAMPAIGNS]}>
    <ModalForm
      trigger={
        <CreateCreativeLink
          {...props}
          variant='solid'
          leftIcon={<FiPlus />}
          campaignId={campaignId}
        >
          Create Creative
        </CreateCreativeLink>
      }
      form={<CreativeFormCreateForCurrentCampaignForm />}
    />
  </PermissionsGate>
);

export const EditCreativeModalForm = ({
  creativeId,
  ...props
}: CreativeIdProps & ButtonProps): JSX.Element => {
  const canEdit = userHasRequiredPermissions([CAN_EDIT_CAMPAIGNS]);
  const canPreview = userHasRequiredPermissions([CAN_PREVIEW_CAMPAIGNS]);
  const icon = canEdit ? <FiEdit /> : <FiEye />;
  const text = canEdit ? 'Edit' : 'Preview';

  if (canEdit || canPreview) {
    return (
      <ModalForm
        trigger={
          <EditCreativeLink
            {...props}
            variant='solid'
            leftIcon={icon}
            creativeId={creativeId}
          >
            {text}
          </EditCreativeLink>
        }
        form={<CreativeFormEdit creativeId={creativeId} />}
      />
    );
  }
  return <></>;
};

export const EditCurrentCreativeModalForm = withCurrentCreativeId(
  EditCreativeModalForm,
);
