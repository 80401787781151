import { useState, useEffect } from 'react';
import { useAppSelector } from 'hooks';
import { selectBasketStatusById } from 'store/baskets';

export const useBasketStatusID = (
  requiredStatus: string[],
  basketId: number,
): boolean => {
  const basketStatus = useAppSelector(state =>
    selectBasketStatusById(state, basketId),
  );
  const [hasRequiredStatus, setHasRequiredStatus] = useState(false);

  useEffect(() => {
    if (basketStatus === null || basketStatus === undefined) {
      return;
    }

    requiredStatus.includes(basketStatus)
      ? setHasRequiredStatus(true)
      : setHasRequiredStatus(false);
  }, [basketStatus, requiredStatus]);

  return hasRequiredStatus;
};
