import * as React from 'react';
import { Box, BoxProps, Tooltip, useStyleConfig } from '@chakra-ui/react';

export const TextOverflow = ({ children, ...props }: BoxProps): JSX.Element => {
  const style = useStyleConfig('TextOverflow');
  return (
    <Tooltip hasArrow placement='top' label={children}>
      <Box as='span' {...props} __css={style}>
        {children}
      </Box>
    </Tooltip>
  );
};
