import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import AuthService from 'services/auth';
import { removeAllClients } from '../clients';
import { removeAllCampaigns } from '../campaigns';
import { removeAllCreatives } from '../creatives';
import { removeAllLineitems } from '../lineitems';
import { removeAllStatistics } from '../statistics';
import { NAME } from './user.consts';
import { normalizeUserData } from './user.utils';
import { removeAllProducts } from 'store/products';
import { removeAllProposals } from 'store/proposals';
import { removeAllAdSlots } from 'store/adslots';
import { removeAllAdFormats } from 'store/adformats';
import { removeAllUsers } from 'store/users';
import { removeAllBaskets } from 'store/baskets';

export const login = createAsyncThunk(
  NAME + '/login',
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const {
        data: { token },
      } = await AuthService.login({ username, password });
      const { data: user } = await AuthService.userProfile({ token });
      return { ...normalizeUserData(user), token };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const loginOcta = createAsyncThunk(
  NAME + '/loginOcta',
  async ({ oktaToken }, { rejectWithValue }) => {
    try {
      const {
        data: { token },
      } = await AuthService.loginOcta({ oktaToken });
      const { data: user } = await AuthService.userProfile({ token });
      return { ...normalizeUserData(user), token };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export const logout = createAsyncThunk(
  NAME + '/logout',
  async (_, { rejectWithValue, getState, dispatch }) => {
    function clearEverything() {
      dispatch(removeAllCampaigns());
      dispatch(removeAllCreatives());
      dispatch(removeAllLineitems());
      dispatch(removeAllStatistics());
      dispatch(removeAllClients());
      dispatch(removeAllProducts());
      dispatch(removeAllProposals());
      dispatch(removeAllAdFormats());
      dispatch(removeAllAdSlots());
      dispatch(removeAllUsers());
      dispatch(removeAllBaskets());
    }

    try {
      const token = getState().user.token;
      const response = await AuthService.logout({ token });
      clearEverything();
      return response.data;
    } catch (error) {
      clearEverything();
      return rejectWithValue(error);
    }
  },
);

export const ping = createAsyncThunk(
  NAME + '/ping',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const token = getState().user.token;
      const response = await adserver({
        url: '/user/me',
        headers: { NadUserTimeout: 'continue' },
      });
      return { ...normalizeUserData(response.data), token };
    } catch (error) {
      dispatch(logout());
      return rejectWithValue(error);
    }
  },
);
