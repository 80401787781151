import * as React from 'react';
import { useFormContext, useFormState } from 'react-hook-form';
import {
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useAppDispatch } from 'hooks';
import { AsyncThunkAction, unwrapResult } from '@reduxjs/toolkit';
import { useHistory } from 'react-router-dom';

interface Button {
  children?: React.ReactNode;
  [key: string]: any;
}

interface ActionButtonProps extends Button {
  action: AsyncThunkAction<any, any, any>;
  redirectOnSucess?: boolean;
}

export const SubmitButton = ({
  children = 'Zapisz zmiany',
  ...props
}: Button): JSX.Element => {
  const { control } = useFormContext();
  const { isSubmitting } = useFormState({ control });
  return (
    <Button isLoading={isSubmitting} variant='primary' type='submit' {...props}>
      {children}
    </Button>
  );
};

export const ResetButton = ({
  children = 'Zresetuj zmiany',
  ...props
}: Button): JSX.Element => {
  const { control, reset } = useFormContext();
  const { isSubmitting, isDirty } = useFormState({ control });
  const [disabled, setDisabled] = React.useState(true);
  function handleClick(): void {
    reset();
  }
  React.useEffect(() => {
    if (isDirty) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [isDirty]);
  return (
    <Button
      isLoading={isSubmitting}
      onClick={handleClick}
      isDisabled={disabled}
      {...props}
    >
      {children}
    </Button>
  );
};

export const ActionButton = ({
  children,
  action,
  redirectOnSucess,
  ...props
}: ActionButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const toast = useToast({
    position: 'bottom',
    duration: 5000,
    isClosable: true,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();

  const handleClick = async () => {
    try {
      setIsSubmitting(true);
      const actionResponse = await dispatch(action);
      unwrapResult(actionResponse);
      toast({
        title: 'Akcja zakończona sukcesem',
        status: 'success',
      });
      if (redirectOnSucess) {
        history.goBack();
      }
    } catch (error) {
      toast({
        title: 'Wystąpił błąd',
        status: 'error',
      });
    }
  };

  return (
    <Button isLoading={isSubmitting} onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};

export const ToastButton = ({ children, ...props }: Button): JSX.Element => {
  const toast = useToast({
    position: 'bottom',
    duration: 5000,
    isClosable: true,
  });

  const [isDisabled, setIsDisabled] = React.useState(false);

  const handleClick = () => {
    toast({
      title: 'Zlecenie zostało wygenerowane',
      status: 'success',
    });
    setIsDisabled(true);
  };

  return (
    <Button onClick={handleClick} disabled={isDisabled} {...props}>
      {children}
    </Button>
  );
};

export const ActionButtonWithConfirmation = ({
  children,
  action,
  redirectOnSucess,
  ...props
}: ActionButtonProps): JSX.Element => {
  const dispatch = useAppDispatch();
  const toast = useToast({
    position: 'bottom',
    duration: 5000,
    isClosable: true,
  });
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const history = useHistory();

  const [isAlertOpen, setIsAlertOpen] = React.useState(false);
  const onClose = () => setIsAlertOpen(false);

  const actionRef = React.useRef();
  const mountedComponentRef = React.useRef();

  const handleClick = () => setIsAlertOpen(true);

  const handleAction = async () => {
    try {
      setIsSubmitting(true);
      const actionResponse = await dispatch(action);
      unwrapResult(actionResponse);
      if (mountedComponentRef.current) setIsSubmitting(false);
      toast({
        title: 'Akcja zakończona sukcesem',
        status: 'success',
      });

      if (redirectOnSucess) {
        history.goBack();
      }
    } catch (error) {
      toast({
        title: 'Wystąpił błąd',
        status: 'error',
      });
    }
  };

  React.useEffect(() => {
    mountedComponentRef.current = true;
    return () => {
      mountedComponentRef.current = false;
    };
  }, []);

  return (
    <>
      <Button isLoading={isSubmitting} onClick={handleClick} {...props}>
        {children}
      </Button>

      <AlertDialog
        isOpen={isAlertOpen}
        leastDestructiveRef={actionRef}
        onClose={onClose}
        closeOnOverlayClick={!isSubmitting}
        closeOnEsc={!isSubmitting}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='xl' fontWeight='bold'>
              {children}
            </AlertDialogHeader>

            <AlertDialogBody>Czy jesteś pewien?</AlertDialogBody>

            <AlertDialogFooter>
              <Button
                ref={actionRef}
                onClick={onClose}
                isDisabled={isSubmitting}
                minWidth='12ch'
              >
                Anuluj
              </Button>
              <Button
                onClick={handleAction}
                ml={3}
                isLoading={isSubmitting}
                minWidth='12ch'
                {...props}
              >
                {children}
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
