/* eslint-disable no-unused-vars */
import { createSelector } from 'reselect';
import { pick } from 'lodash';
import {
  selectCurrentLineitemId,
  selectLineitemsOfCurrentCampaign,
} from 'store/lineitems';
import { selectCurrentCampaignId } from 'store/campaigns';
import {
  STATISTIC_KEYS,
  STAT_BIDFLOOR,
  ENTITY_CAMPAIGN,
  ENTITY_LINEITEM,
  TIMEUNIT_TOTAL,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  selectAllLineitems,
  selectAllWebsites,
  selectAllOperatingsystems,
  selectAllCities,
  selectAllCoordinates,
  selectAllApps,
  selectAllBidfloors,
  selectAllExchanges,
  selectAllCreatives,
} from 'store/statistics';

export const selectTotalStatsForAllLineitems = createSelector(
  selectAllLineitems,
  allLineitems =>
    allLineitems.filter(lineitem => lineitem.timeunit === TIMEUNIT_TOTAL),
);

export const selectMonthlyStatsForAllLineitems = createSelector(
  selectAllLineitems,
  allLineitems =>
    allLineitems.filter(lineitem => lineitem.timeunit === TIMEUNIT_MONTH),
);

export const selectWeeklyStatsForAllLineitems = createSelector(
  selectAllLineitems,
  allLineitems =>
    allLineitems.filter(lineitem => lineitem.timeunit === TIMEUNIT_WEEK),
);

export const selectDailyStatsForAllLineitems = createSelector(
  selectAllLineitems,
  allLineitems =>
    allLineitems.filter(lineitem => lineitem.timeunit === TIMEUNIT_DAY),
);

export const selectHourlyStatsForAllLineitems = createSelector(
  selectAllLineitems,
  allLineitems =>
    allLineitems.filter(lineitem => lineitem.timeunit === TIMEUNIT_HOUR),
);

export const selectTotalStatsForCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectTotalStatsForAllLineitems,
  (selectCurrentLineitemId, totalStatsForAllLineitems) =>
    totalStatsForAllLineitems.find(
      lineitem => lineitem[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || {},
);

export const selectMonthlyStatsForCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectMonthlyStatsForAllLineitems,
  (selectCurrentLineitemId, monthlyStatsForAllLineitems) =>
    monthlyStatsForAllLineitems.filter(
      lineitem => lineitem[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectWeeklyStatsForCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectWeeklyStatsForAllLineitems,
  (selectCurrentLineitemId, weeklyStatsForAllLineitems) =>
    weeklyStatsForAllLineitems.filter(
      lineitem => lineitem[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectDailyStatsForCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectDailyStatsForAllLineitems,
  (selectCurrentLineitemId, dailyStatsForAllLineitems) =>
    dailyStatsForAllLineitems.filter(
      lineitem => lineitem[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectLastWeekStatsForCurrentLineitem = createSelector(
  selectDailyStatsForCurrentLineitem,
  dailyStatsForCurrentLineitem => dailyStatsForCurrentLineitem.slice(-7) || [],
);

export const selectHourlyStatsForCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectHourlyStatsForAllLineitems,
  (selectCurrentLineitemId, hourlyStatsForAllLineitems) =>
    hourlyStatsForAllLineitems.filter(
      lineitem => lineitem[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForAppsInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllApps,
  (selectCurrentLineitemId, allApps) =>
    allApps.filter(app => app[ENTITY_LINEITEM] === selectCurrentLineitemId) ||
    [],
);

export const selectTotalStatsForWebsitesInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllWebsites,
  (selectCurrentLineitemId, allWebsites) =>
    allWebsites.filter(
      website => website[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForOperatingsystemsInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllOperatingsystems,
  (selectCurrentLineitemId, allOperatingsystems) =>
    allOperatingsystems.filter(
      os => os[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForCitiesInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllCities,
  (selectCurrentLineitemId, allCities) =>
    allCities.filter(
      city => city[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForCoordinatesInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllCoordinates,
  (selectCurrentLineitemId, allCoordinates) =>
    allCoordinates.filter(
      coordinate => coordinate[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForExchangesInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllExchanges,
  (selectCurrentLineitemId, allExchanges) =>
    allExchanges.filter(
      exchange => exchange[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForCreativesInCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllCreatives,
  (selectCurrentLineitemId, allCreatives) =>
    allCreatives.filter(
      creative => creative[ENTITY_LINEITEM] === selectCurrentLineitemId,
    ) || [],
);

export const selectTotalStatsForLineitemsOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllLineitems,
  (currentCampaignId, allLineitems) =>
    allLineitems.filter(
      lineitem => lineitem[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectLineitemsOfCurrentCampaignWithStats = createSelector(
  selectLineitemsOfCurrentCampaign,
  selectTotalStatsForLineitemsOfCurrentCampaign,
  (lineitems, statsForLineitems) =>
    lineitems.map(lineitem => {
      // zapobiega kolizji kluczy (np. id), wybierając tylko zdefiniowane w stałych klucze statystyk
      const statistics = pick(
        statsForLineitems.find(stat => stat[ENTITY_LINEITEM] === lineitem.id),
        STATISTIC_KEYS,
      );
      return {
        ...statistics,
        ...lineitem,
      };
    }) || [],
);

export const selectBidfloorForCurrentLineitem = createSelector(
  selectCurrentLineitemId,
  selectAllBidfloors,
  (currentLineitemId, allBidfloors) =>
    allBidfloors.find(bidfloor => bidfloor.lineitemId === currentLineitemId),
);
