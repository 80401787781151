import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { isBefore, max } from 'date-fns';
import { ControlledInputDate } from 'components';
import { useAppSelector } from 'hooks';
import { selectCurrentCampaign } from 'store/campaigns';
import { startOfToday } from 'consts/dates';

const STARTDATE = 'general.timing.startDate';

const LineitemGeneralTimingStartDate = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const campaign = useAppSelector(selectCurrentCampaign);
  const { startDate: campaignStartDate } = campaign || {};
  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: startDateValue },
  } = useController({
    name: STARTDATE,
    control,
  });
  const {
    field: { value: endDateValue },
  } = useController({
    name: 'general.timing.endDate',
    control,
  });

  let minDate, maxDate;

  if (startOfToday instanceof Date && campaignStartDate instanceof Date) {
    minDate = max([startOfToday, campaignStartDate]);
  }

  if (minDate instanceof Date && endDateValue instanceof Date) {
    maxDate = max([minDate, endDateValue]);
  }

  React.useEffect(() => {
    const startOfLineitemIsInThePast = isBefore(startDateValue, startOfToday);
    setIsDisabled(startOfLineitemIsInThePast);
  }, [startDateValue, startOfToday, isBefore]);

  return (
    <ControlledInputDate
      label='Start Date'
      name={STARTDATE}
      type='date'
      min={minDate}
      max={maxDate}
      isDisabled={isDisabled}
      withInputTime
      {...props}
    />
  );
};

LineitemGeneralTimingStartDate.displayName =
  'Lineitem.general.timing.startDate';

export { LineitemGeneralTimingStartDate };
