import React from 'react';
import userHasRequiredPermissions from 'hooks/useUserPermissions';

export interface PermissionsGateProps {
  requiredPermissions: string[];
  children?: React.ReactNode;
}

export const PermissionsGate = ({
  requiredPermissions = [],
  children,
}: PermissionsGateProps): JSX.Element | null => {
  const hasPermissions = userHasRequiredPermissions(requiredPermissions);

  if (!hasPermissions) return null;

  return <>{children}</>;
};
