import React from 'react';
import { Input } from '@chakra-ui/react';
import {
  ParamInputText,
  ParamInputNumber,
  ParamBoolean,
  ParamTextarea,
  ParamInputFile,
  ParamSelect,
  ParamSelectMultiple,
  ParamMacroList,
} from 'store/consts';
import {
  ControlledInputArray,
  ControlledInputText,
  InputNumber,
  ControlledInputNumber,
  ControlledCheckboxBoolean,
  ControlledSelect,
  ControlledSelectMultiple,
  ControlledTextarea,
  ControlledInputFileWithUpload,
  Textarea,
} from 'components';
import { formContext } from 'components/Form.Context';
// import { CreativeMacroList } from '../formFields/Creative.macroList';

/**
 * Pojedyncze pole formularza (input, select, etc.).
 * Komponent na bazie konfiguracji dobiera i konfiguruje odpowiedni typ komponentu inputu
 * @returns JSX.Element
 */
const TemplateParam = ({
  type,
  subType,
  maxElements,
  ...props
}: (
  | ParamInputText
  | ParamInputNumber
  | ParamBoolean
  | ParamTextarea
  | ParamInputFile
  | ParamSelect
  | ParamSelectMultiple
  | ParamMacroList
) & { subType?: string; maxElements?: number }): JSX.Element | null => {
  const { campaignId } = React.useContext(formContext);
  switch (type) {
    case 'text':
      switch (subType) {
        case 'text':
          if (maxElements)
            return <ControlledInputArray component={Input} {...props} />;
          return <ControlledInputText {...props} />;
        case 'number':
          if (maxElements)
            return <ControlledInputArray component={InputNumber} {...props} />;
          return <ControlledInputNumber {...props} />;
        default:
          return null;
      }
    case 'bool':
      return <ControlledCheckboxBoolean {...props} />;
    case 'dropdown':
      return <ControlledSelect {...props} />;
    case 'multidropdown':
      return <ControlledSelectMultiple {...props} />;
    case 'longtext':
      if (maxElements)
        return (
          <ControlledInputArray isExpandable component={Textarea} {...props} />
        );
      return <ControlledTextarea {...props} />;
    case 'file':
      return (
        <ControlledInputFileWithUpload campaignId={campaignId} {...props} />
      );
    // case 'macrolist':
    //   return <CreativeMacroList {...props} />;
    default:
      return null;
  }
};

TemplateParam.displayName = 'CreativeForm.TemplateParam';

export { TemplateParam };
