import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Link, generatePath } from 'react-router-dom';
import { LINEITEM_CREATE, LINEITEM_SHOW, LINEITEM_EDIT } from 'routes';
import { withCurrentLineitemId } from 'hoc';
import { useAppSelector } from 'hooks';
import { selectLineitem } from 'store/lineitems';
import { LineitemInterface } from './Lineitem.schema';
import { CampaignId } from 'components/Campaign';
import { FiEdit } from 'react-icons/fi';

type LineitemIdProps = {
  lineitemId: number;
};

export function lineitemCreatePage(campaignId: number): string {
  return generatePath(LINEITEM_CREATE, { campaignId });
}

export function lineitemShowPage(lineitem: LineitemInterface): string {
  return generatePath(LINEITEM_SHOW, {
    campaignId: lineitem.campaignId,
    lineitemId: lineitem.id,
  });
}

export function lineitemEditPage(lineitem: LineitemInterface): string {
  return generatePath(LINEITEM_EDIT, {
    campaignId: lineitem.campaignId,
    lineitemId: lineitem.id,
  });
}

export const CreateLineitemLink = ({
  campaignId,
  children,
  ...props
}: CampaignId & ButtonProps): JSX.Element => (
  <Button to={lineitemCreatePage(campaignId)} as={Link} {...props}>
    {children}
  </Button>
);

export const ShowLineitemLink = ({
  lineitemId,
  children,
  ...props
}: LineitemIdProps & ButtonProps): JSX.Element => {
  const lineitem = useAppSelector(state => selectLineitem(state, lineitemId));
  return (
    <Button to={lineitemShowPage(lineitem)} as={Link} {...props}>
      {children}
    </Button>
  );
};

export const EditLineitemLink = ({
  lineitemId,
  children,
  ...props
}: LineitemIdProps & ButtonProps): JSX.Element => {
  const lineitem = useAppSelector(state => selectLineitem(state, lineitemId));
  return (
    <Button
      leftIcon={<FiEdit />}
      to={lineitemEditPage(lineitem)}
      as={Link}
      {...props}
    >
      {children}
    </Button>
  );
};

export const ShowCurrentLineitemLink = withCurrentLineitemId(ShowLineitemLink);
export const EditCurrentLineitemLink = withCurrentLineitemId(EditLineitemLink);
