import React from 'react';
import { CreativeTemplateFieldsetType } from 'store/consts';
import { Fieldset } from 'components';
import { TemplateParam } from './CreativeForm.TemplateParam';

/**
 * Fieldset dla parametrów kreacji; podział jest dokonywany w reducerze na bazie danych z api
 */
const TemplateFieldset = (
  props: CreativeTemplateFieldsetType,
): JSX.Element | null => {
  const columns = React.useMemo(() => {
    if (props.params.length === 1) return 1;
    if (props.params.length === 2) return 2;
    return 3;
  }, [props.params.length]);

  return (
    <Fieldset heading={props.label} description={props.tip} columns={columns}>
      {props.params.map(param => (
        <TemplateParam key={param.name} {...param} />
      ))}
    </Fieldset>
  );
};
TemplateFieldset.displayName = 'CreativeForm.TemplateFieldset';

export { TemplateFieldset };
