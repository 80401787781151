import * as React from 'react';
import { Flex, Box, Text, Image } from '@chakra-ui/react';

const Website = ({ domain }: { domain?: string }): JSX.Element => (
  <Flex alignItems='center'>
    <Image
      w='1.5em'
      h='1.5em'
      background='graphite.400'
      borderRadius='base'
      mr={2}
      src={`https://www.google.com/s2/favicons?domain=${domain}`}
    />
    <Box>
      <Text>{domain || 'Unknown website'}</Text>
    </Box>
  </Flex>
);

export default Website;
