import { fetchById as fetchProposalById } from 'store/proposals';
import { store } from 'store';
import Notification from './notification.type';
import { fetchById as fetchChatById } from 'store/chats';
import { fetchAllBasketsOfCampaign } from 'store/baskets';
import { fetchAllFlightsForCurrentProposal } from 'store/flights';

export const notificationHandler = (notification: Notification): void => {
  const { object, id, context } = notification;
  if (object === 'proposal') {
    store.dispatch(fetchProposalById({ proposalId: id }));
    if (context === 'messages') {
      store.dispatch(fetchChatById({ chatId: id }));
    }
    if (context === 'flights') {
      store.dispatch(fetchAllFlightsForCurrentProposal());
    }
  }
  if (object === 'campaign') {
    if (context === 'basket') {
      store.dispatch(fetchAllBasketsOfCampaign({ campaignId: id }));
    }
  }
};
