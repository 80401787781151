import React from 'react';
import { Grid, GridProps, Box, Heading, Text } from '@chakra-ui/react';
import {
  Fieldset as FieldsetLayout,
  FieldsetProps as FieldsetLayoutProps,
} from 'layouts';

type FieldsetProps = {
  heading?: string;
  description?: string;
  columns?: number;
  children?: React.ReactNode;
};

const Fieldset = ({
  heading,
  description,
  columns = 12,
  children,
  ...props
}: FieldsetProps & GridProps & FieldsetLayoutProps): JSX.Element => (
  <Grid
    as={FieldsetLayout}
    gap={4}
    templateColumns={`repeat(${columns},1fr)`}
    {...props}
  >
    {(heading || description) && (
      <Box gridColumn={`span ${columns}`}>
        {heading && <Heading size='md'>{heading}</Heading>}
        {description && <Text>{description}</Text>}
      </Box>
    )}
    {children}
  </Grid>
);

Fieldset.displayName = 'Form.Fieldset';

export { Fieldset };
