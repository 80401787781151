export default {
  baseStyle: {
    fontWeight: 'bold',
  },
  variants: {
    block: {
      
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'end',
      textAlign: 'end',
      height: 8,
      mb: 0,
      mt: 0,
      mr: 10,
      lineHeight: 'shorter',
    },
  },
};
