import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter();
const initialState = {
  days: [
    { id: 0, name: 'Monday' },
    { id: 1, name: 'Tuesday' },
    { id: 2, name: 'Wednesday' },
    { id: 3, name: 'Thursday' },
    { id: 4, name: 'Friday' },
    { id: 5, name: 'Saturday' },
    { id: 6, name: 'Sunday' },
  ],
};

export { initialState };
export default adapter;
