import { createSelector } from 'reselect';
import { RootState } from 'store';
import { selectCurrentCampaignId } from '../campaigns/';
import { HistoryEvent } from './history.types';
import { adapter } from './history.adapter';
import { REDUCER_KEY, ENTITY_CAMPAIGN } from './history.consts';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectFullHistoryOfCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAll,
  (currentCampaignId: number, allHistoryEvents: HistoryEvent[]) =>
    allHistoryEvents.filter(
      (historyEvent: HistoryEvent) =>
        historyEvent.campaignId === currentCampaignId,
    ),
);

export const selectHistoryOfCurrentCampaign = createSelector(
  selectFullHistoryOfCurrentCampaign,
  (fullHistoryOfCurrentCampaign: HistoryEvent[]) =>
    fullHistoryOfCurrentCampaign.filter(
      (historyEvent: HistoryEvent) =>
        historyEvent.entityType === ENTITY_CAMPAIGN,
    ),
);
