import * as React from 'react';
import {
  Box,
  Grid,
  Input,
  InputProps,
  StylesProvider,
  useStyles,
  useMultiStyleConfig,
  useOutsideClick,
} from '@chakra-ui/react';

interface SuggestionProps {
  value: string; // wartość ustawiana do komponentu Input
  display: string | React.Component; // wyświetlane na liście opcji
  search: string; // string do filtrowania
}

interface InputTextWithAutocompletionProps {
  suggestions: string[] | SuggestionProps[];
  onChange: (value: string | null) => void;
}

const REQUIRED_STRING_LENGTH = 3;
const MAX_NO_OF_RESULTS = 10;

export const InputTextWithAutocompletion = ({
  suggestions: suggestionProp,
  size,
  variant,
  value = '',
  onChange,
  onFocus,
  ...props
}: InputTextWithAutocompletionProps & InputProps): JSX.Element => {
  const ref = React.useRef<HTMLDivElement>(null);
  const styles = useMultiStyleConfig('Select', { size, variant });
  const [inputValue, setInputValue] = React.useState(value.toString());
  const [isFocused, setIsFocused] = React.useState(false);

  const suggestions: Array<string | SuggestionProps> = React.useMemo(() => {
    return suggestionProp.map(suggestion => {
      if (typeof suggestion === 'string') {
        return suggestion.toLowerCase();
      }
      if (typeof suggestion === 'object') {
        return {
          ...suggestion,
          search: suggestion.search.toLowerCase(),
        };
      }
    });
  }, [suggestionProp]);

  useOutsideClick({
    ref: ref,
    handler: () => setIsFocused(false),
  });

  React.useEffect(() => {
    setInputValue(value.toString());
  }, [value]);

  React.useEffect(() => {
    if (onChange) onChange(inputValue);
  }, [inputValue]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    setIsFocused(true);
    if (onFocus) onFocus(event);
  };

  const handleOptionClick = (value: string) => {
    setIsFocused(false);
    setInputValue(value);
  };

  const dropdown = React.useMemo(() => {
    if (!suggestions || !isFocused) return null;
    if (inputValue.length < REQUIRED_STRING_LENGTH)
      return <Message>Type at least three letters</Message>;

    const matchingSuggestion = suggestions
      .filter(suggestion => {
        if (typeof suggestion === 'string') {
          return suggestion.includes(inputValue.toLowerCase());
        }
        if (typeof suggestion === 'object') {
          return suggestion.search.includes(inputValue.toLowerCase());
        }
      })
      .slice(0, MAX_NO_OF_RESULTS);

    if (!matchingSuggestion || matchingSuggestion.length === 0)
      return <Message>No results found</Message>;

    return (
      <Options values={matchingSuggestion || []} onClick={handleOptionClick} />
    );
  }, [isFocused, inputValue, suggestions]);

  return (
    <StylesProvider value={styles}>
      <Box position='relative' ref={ref}>
        <Input
          {...props}
          value={inputValue}
          size={size}
          variant={variant}
          onChange={handleChange}
          onFocus={handleFocus}
        />
        {dropdown}
      </Box>
    </StylesProvider>
  );
};

const Dropdown = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const styles = useStyles();
  return (
    <Box
      outline='none'
      position='absolute'
      left={0}
      top='110%'
      width='100%'
      zIndex='dropdown'
    >
      <Grid __css={styles.dropdown}>{children}</Grid>
    </Box>
  );
};

const Options = ({
  values,
  onClick,
}: {
  values: (string | SuggestionProps)[];
  onClick: (value: string) => void;
}): JSX.Element => {
  const styles = useStyles();
  return (
    <Dropdown>
      <Grid __css={styles.options}>
        {values.map(option => {
          const value = typeof option === 'object' ? option.value : option;
          const display = typeof option === 'object' ? option.display : option;

          return (
            <Box
              key={value}
              __css={styles.option}
              onClick={() => onClick(value)}
            >
              {display}
            </Box>
          );
        })}
      </Grid>
    </Dropdown>
  );
};

const Message = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Dropdown>
    <Box fontSize='xs' m={2}>
      {children}
    </Box>
  </Dropdown>
);
