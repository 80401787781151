import * as React from 'react';
import { HStack, Box, Button, ButtonGroup, Center } from '@chakra-ui/react';
import { BsFillSquareFill, BsFillCircleFill } from 'react-icons/bs';

export type ChartLegendItemProps = {
  dataKey: string;
  color: string;
  inactive: boolean;
  type: string;
  value: string;
};

export type ChartLegendEvents = {
  onClick?: (data: ChartLegendItemProps) => void;
  onMouseOver?: (data: ChartLegendItemProps) => void;
  onMouseOut?: (data: ChartLegendItemProps) => void;
};

export type ChartLegendProps = {
  payload?: Array<ChartLegendItemProps>;
};

const ChartLegendItem = ({ ...item }: ChartLegendItemProps) => {
  const { color, inactive, type, value } = item;
  const Icon = type === 'line' ? BsFillCircleFill : BsFillSquareFill;
  return (
    <HStack>
      <Icon fill={!inactive ? color : undefined} />
      <Box fontWeight='bold'>{value}</Box>
    </HStack>
  );
};

const ChartLegendInteractiveItem = ({
  onClick,
  onMouseOver,
  onMouseOut,
  ...item
}: ChartLegendItemProps & ChartLegendEvents) => {
  const { color, inactive, type, value } = item;
  const Icon = type === 'line' ? BsFillCircleFill : BsFillSquareFill;
  const handleClick = () => {
    if (typeof onClick === 'function') onClick(item);
  };
  const handleMouseOver = () => {
    if (typeof onMouseOver === 'function') onMouseOver(item);
  };
  const handleMouseOut = () => {
    if (typeof onMouseOut === 'function') onMouseOut(item);
  };
  return (
    <Button
      colorScheme='gray'
      leftIcon={<Icon fill={!inactive ? color : undefined} />}
      onClick={handleClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
    >
      {value}
    </Button>
  );
};

export const ChartLegend = ({
  payload,
}: ChartLegendProps): JSX.Element | null => {
  return (
    <Center>
      <HStack spacing={6} fontSize='sm'>
        {payload?.map(item => (
          <ChartLegendItem key={item.dataKey} {...item} />
        ))}
      </HStack>
    </Center>
  );
};

export const ChartInteractiveLegend = ({
  payload,
  onClick,
  onMouseOver,
  onMouseOut,
}: ChartLegendProps & ChartLegendEvents): JSX.Element | null => {
  return (
    <Center>
      <ButtonGroup spacing={0} size='sm' variant='ghost' colorScheme='gray'>
        {payload?.map(item => (
          <ChartLegendInteractiveItem
            key={item.dataKey}
            {...item}
            onClick={onClick}
            onMouseOver={onMouseOver}
            onMouseOut={onMouseOut}
          />
        ))}
      </ButtonGroup>
    </Center>
  );
};
