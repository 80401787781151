import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { max } from 'date-fns';
import { ControlledInputDate } from 'components';
import { useAppSelector } from 'hooks';
import { selectCurrentCampaign } from 'store/campaigns';
import { endOfToday } from 'consts/dates';

const LineitemGeneralTimingEndDate = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const campaign = useAppSelector(selectCurrentCampaign);
  const { control } = useFormContext();
  const {
    field: { value: startDateValue },
  } = useController({
    name: 'general.timing.startDate',
    control,
  });
  const { endDate: campaignEndDate } = campaign || {};

  let minDate, maxDate;

  if (endOfToday instanceof Date && startDateValue instanceof Date) {
    minDate = max([endOfToday, startDateValue]);
  }

  if (minDate instanceof Date && campaignEndDate instanceof Date) {
    maxDate = max([minDate, campaignEndDate]);
  }

  return (
    <ControlledInputDate
      label='End Date'
      name='general.timing.endDate'
      type='date'
      min={minDate}
      max={maxDate}
      withInputTime
      {...props}
    />
  );
};

LineitemGeneralTimingEndDate.displayName = 'Lineitem.general.timing.endDate';

export { LineitemGeneralTimingEndDate };
