import { mode } from '@chakra-ui/theme-tools';

export default {
  baseStyle: props => ({
    popper: {
      maxWidth: 'fit-content',
    },
    content: {
      bg: mode('white.100', 'dark.100')(props),
    },
  }),
};
