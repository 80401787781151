import React from 'react';
import { ControlledInputNumber } from 'components';

const LineitemGeneralEventPlanEventCount = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputNumber
    name='general.eventPlan.eventCount'
    label='Event Count'
    min={0}
    step={1}
    precision={0}
    {...props}
  />
);

LineitemGeneralEventPlanEventCount.displayName =
  'Lineitem.general.eventPlan.eventCount';

export { LineitemGeneralEventPlanEventCount };
