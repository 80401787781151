/**
 * komponenty Checkbox i CheckboxGroup dla eventu onChange event castują zwracaną wartość do stringa
 * jako workaround stosuję propa z typem wartości, jaka ma być zwracana
 * @see https://github.com/chakra-ui/chakra-ui/issues/2931
 */
export function convertValue(type, value) {
  switch (type) {
    case 'string':
      return value.toString();
    case 'bool':
      return value === 'true' || value === true;
    case 'float':
      return parseFloat(value);
    case 'int':
      return parseInt(value);
    default:
      return value;
  }
}

export function convertValues(type, values = []) {
  return values.map(value => convertValue(type, value));
}
