import React from 'react';
import { ControlledCheckboxGroup } from 'components';

const DAYS = [
  { id: '0', name: 'Mon' },
  { id: '1', name: 'Tue' },
  { id: '2', name: 'Wed' },
  { id: '3', name: 'Thu' },
  { id: '4', name: 'Fri' },
  { id: '5', name: 'Sat' },
  { id: '6', name: 'Sun' },
];

const LineitemGeneralTimingDailyTiming = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  return (
    <ControlledCheckboxGroup
      name='general.timing.dailyTiming'
      label='Days'
      valueType='int'
      values={DAYS}
      w='100%'
      {...props}
    />
  );
};

LineitemGeneralTimingDailyTiming.displayName =
  'Lineitem.general.timing.dailyTiming';

export { LineitemGeneralTimingDailyTiming };
