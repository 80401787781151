import React from 'react';
import PropTypes from 'prop-types';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  Box,
  Flex,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  CircularProgress,
  useStyleConfig,
} from '@chakra-ui/react';
import NumberFormat from 'react-number-format';

export const Card = ({ children, size, variant, ...props }) => {
  const style = useStyleConfig('Card', { size, variant });
  return (
    <Box __css={style} position='relative' {...props}>
      {children}
    </Box>
  );
};

Card.propTypes = {
  size: PropTypes.string,
  variant: PropTypes.string,
  children: PropTypes.node.isRequired,
};

const Header = ({ children }) => (
  <Heading as='h5' size='sm' pb={2}>
    {children}
  </Heading>
);

Header.propTypes = {
  children: PropTypes.node.isRequired,
};

const Stats = ({ label, value, description, suffix }) => (
  <Stat ml={1}>
    <StatLabel mt={2} mb={-1}>
      {label}
    </StatLabel>
    <StatNumber fontSize='3xl'>
      <NumberFormat
        value={value}
        displayType='text'
        thousandSeparator=' '
        decimalSeparator=','
      />{' '}
      {suffix}
    </StatNumber>
    {description && (
      <StatHelpText fontWeight='bold' opacity={0.5}>
        {description}
      </StatHelpText>
    )}
  </Stat>
);

Stats.propTypes = {
  value: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  description: PropTypes.string,
  suffix: PropTypes.string.isRequired,
};

const CircularChart = ({
  result,
  remainingValue,
  suffix,
  totalValue,
  spentValue,
}) => (
  <Popover trigger='hover'>
    <PopoverTrigger>
      <Box position='relative' m={-1}>
        <Box position='absolute' w='100%' h='100%'>
          <Flex
            alignItems='center'
            justifyContent='center'
            w='100%'
            h='100%'
            position='absolute'
            fontSize='xl'
            color='primary'
            fontWeight='bold'
          >
            {result}%
          </Flex>
          <Box
            position='absolute'
            top='15%'
            left='15%'
            w='70%'
            h='70%'
            borderRadius='full'
            bg='primary'
            opacity={0.1}
          />
        </Box>
        <CircularProgress
          thickness={4}
          size={20}
          trackColor='none'
          color='primary'
          value={result}
        />
      </Box>
    </PopoverTrigger>
    <PopoverContent>
      <PopoverArrow />
      <PopoverBody>
        <Stat>
          <StatLabel>Total Budget:</StatLabel>
          <StatNumber fontSize='xl'>
            <NumberFormat
              value={totalValue}
              displayType='text'
              thousandSeparator=' '
              decimalSeparator=','
            />{' '}
            {suffix}
          </StatNumber>
        </Stat>
      </PopoverBody>
      <PopoverBody>
        <Stat>
          <StatLabel>Budget Spent:</StatLabel>
          <StatNumber fontSize='xl'>
            <NumberFormat
              value={spentValue}
              displayType='text'
              thousandSeparator=' '
              decimalSeparator=','
            />{' '}
            {suffix}
          </StatNumber>
        </Stat>
      </PopoverBody>
      <PopoverBody>
        <Stat>
          <StatLabel>Budget Remaining:</StatLabel>
          <StatNumber fontSize='xl'>
            <NumberFormat
              value={remainingValue}
              displayType='text'
              thousandSeparator=' '
              decimalSeparator=','
            />{' '}
            {suffix}
          </StatNumber>
        </Stat>
      </PopoverBody>
    </PopoverContent>
  </Popover>
);

CircularChart.propTypes = {
  remainingValue: PropTypes.number,
  spentValue: PropTypes.number.isRequired,
  totalValue: PropTypes.number.isRequired,
  result: PropTypes.number,
  suffix: PropTypes.string.isRequired,
};

const Actions = ({ children }) => (
  <Flex
    justifyContent='flex-end'
    gridGap={2}
    position='absolute'
    right={0}
    top={0}
    p={2}
  >
    {children}
  </Flex>
);

Actions.propTypes = {
  children: PropTypes.node.isRequired,
};

Card.Header = Header;
Card.Actions = Actions;
Card.Stats = Stats;
Card.CircularChart = CircularChart;

export default Card;
