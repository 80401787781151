import React from 'react';
import { InputNumber } from 'components/InputNumber';
import { ControlledInputArray } from 'components/ControlledFormFields';

const LineitemGeneralTimingHourlyTiming = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  return (
    <ControlledInputArray
      component={InputNumber}
      name='general.timing.hourlyTiming'
      label='Hours'
      min={0}
      max={24}
      precision={0}
      step={1}
      templateColumns='repeat(2,1fr)'
      isTime={true}
      {...props}
    />
  );
};

LineitemGeneralTimingHourlyTiming.displayName =
  'Lineitem.general.timing.hourlyTiming';

export { LineitemGeneralTimingHourlyTiming };
