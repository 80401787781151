import * as React from 'react';
import { RootState } from 'store';
import { withCurrentLineitemId } from 'hoc';
import { isBefore, startOfToday } from 'date-fns';
import { useAppSelector } from 'hooks';
import { selectLineitem, toggleStatus } from 'store/lineitems';
import { LineitemInterface } from './Lineitem.schema';
import { StatusToggler } from 'components';

export const LineitemStatusToggler = ({
  lineitemId,
}: {
  lineitemId: number;
}): JSX.Element | null => {
  const lineitemSelector = (state: RootState): LineitemInterface =>
    selectLineitem(state, lineitemId);
  const lineitem = useAppSelector(lineitemSelector);
  const toggleAction = () => toggleStatus(lineitemId);

  if (!lineitem || isBefore(lineitem.general.timing.endDate, startOfToday())) {
    return null;
  }

  return (
    <StatusToggler selector={lineitemSelector} toggleAction={toggleAction} />
  );
};

export const CurrentLineitemStatusToggler = withCurrentLineitemId(
  LineitemStatusToggler,
);
