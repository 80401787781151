import * as Yup from 'yup';

const READ_ONLY_FIELDS = {
  id: Yup.number().integer().required(),
};

export const EDITABLE_FIELDS = {
  name: Yup.string().required().default(''),
  format: Yup.number().integer().required().default(1),
  language: Yup.string().required().default('pl'),
  adType: Yup.number().integer().required().default(1),
  viewabilityMeasured: Yup.bool().required().default(true),
  mimeType: Yup.string().required().default('image/jpeg'),
  creativeAttributes: Yup.array(Yup.number().integer()).default([]),
  googleAttributes: Yup.array(Yup.number().integer()).default([]),
  htmlMarkup: Yup.string().required().default(''),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const MODEL_VALIDATION_SCHEMA = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
});
