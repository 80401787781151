import { mode } from '@chakra-ui/theme-tools';

const baseStyle = props => ({
  borderRadius: 'md',
  boxShadow: 'base',
  bg: mode('white.100', 'dark.100')(props),
  '*::-webkit-scrollbar-thumb': {
    borderColor: mode('white.100', 'dark.100')(props),
  },
});

const sizes = {
  sm: {
    padding: 4,
  },
  md: {
    padding: 6,
  },
};

const defaultProps = {
  size: 'md',
};

export default {
  baseStyle,
  sizes,
  defaultProps,
};
