import React from 'react';
import { Tabs, TabList, Tab } from '@chakra-ui/react';
import { CreativeTemplateType } from 'store/consts';

/**
 * Wybór rodzaju szablonu (template) w postaci tabów;
 * wykorzystywane jedynie przy tworzeniu kreacji.
 * Zmiana taba resetuje zawartość formularza do domyślnych wartości.
 */
const TemplateTabs = ({
  selectedIndex,
  templates,
  onChange,
}: {
  selectedIndex: number;
  templates: CreativeTemplateType[];
  onChange: (index: number) => void;
}): JSX.Element => {
  function handleTabChange(index: number) {
    onChange(index);
  }

  return (
    <Tabs index={selectedIndex} onChange={handleTabChange}>
      <TabList margin={0}>
        {templates.map(creativeTemplate => (
          <Tab key={creativeTemplate.id} id={creativeTemplate.id.toString()}>
            {creativeTemplate.label || creativeTemplate.name}
          </Tab>
        ))}
      </TabList>
    </Tabs>
  );
};

TemplateTabs.displayName = 'CreativeForm.TemplateTabs';

export { TemplateTabs };
