import React from 'react';
import PropTypes from 'prop-types';
import { Box, Icon, useStyles } from '@chakra-ui/react';
import { FiCheck } from 'react-icons/fi';

/**
 * pojedyncza opcja
 */
const Option = React.forwardRef(({ children, isSelected, ...props }, ref) => {
  const styles = useStyles();

  return (
    <Box ref={ref} __css={styles.option} position='relative' {...props}>
      {children}
      {isSelected && (
        <Icon
          as={FiCheck}
          position='absolute'
          right={0}
          top='calc(50% - 0.5em)'
        />
      )}
    </Box>
  );
});

Option.displayName = 'Select.Option';

Option.propTypes = {
  isSelected: PropTypes.bool,
  children: PropTypes.node,
};

export default Option;
