import { createSlice } from '@reduxjs/toolkit';
import {
  readyActionHandler,
  pendingActionHandler,
  rejectActionHandler,
} from 'helpers/sliceHelpers';
import adapter, { initialState } from './statistics.adapter';
import { NAME } from './statistics.consts';

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(NAME + '/removeAll', state => adapter.removeAll(state))
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('fulfilled'),
        (state, action) => {
          readyActionHandler(state, action);
          let entityType;
          if (action.payload.length > 0)
            if (action.payload[0]?.entityType === 'lat,long') {
              entityType = '::lat:';
            } else {
              entityType = '::' + action.payload[0]?.entityType + ':' || null;
            }
          if (entityType && state.ids.length > 0) {
            const etityFilterStats = state.ids.filter(id =>
              id.includes(entityType),
            );
            adapter.removeMany(state, etityFilterStats);
          }
          adapter.upsertMany(state, action.payload);
        },
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('pending'),
        pendingActionHandler,
      )
      .addMatcher(
        ({ type }) => type.startsWith(NAME) && type.endsWith('rejected'),
        rejectActionHandler,
      );
  },
});

export const { reducer } = slice;
