import { createEntityAdapter } from '@reduxjs/toolkit';
import { IDLE, NO_ERRORS } from 'consts/xhr';

const adapter = createEntityAdapter();

export const initialState = adapter.getInitialState({
  status: IDLE,
  errors: NO_ERRORS,
});

export default adapter;
