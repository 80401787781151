import React from 'react';
import { ControlledInputNumber } from 'components';

const LineitemTotalBudget = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => (
  <ControlledInputNumber
    name='totalBudget'
    label='Lineitem budget'
    addon='$'
    {...props}
  />
);
LineitemTotalBudget.displayName = 'Lineitem.totalBudget';

export { LineitemTotalBudget };
