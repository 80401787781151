const baseStyle = {
  whiteSpace: 'nowrap',
  borderBottomWidth: '1px',
  borderBottomStyle: 'dashed',
  cursor: 'help',
  display: 'inline-block',
};

export default {
  baseStyle,
};
