import { mode } from '@chakra-ui/theme-tools';

const baseStyle = props => ({
  dialog: {
    bg: mode('white.200', 'dark.200')(props),
    padding: 6,
    display: 'grid',
    gridGap: 4,
  },
  header: {
    padding: 0,
  },
  body: {
    padding: 0,
  },
  footer: {
    padding: 0,
  },
});

export default {
  baseStyle,
};
