import { mode, transparentize } from '@chakra-ui/theme-tools';

/**
 * TODO: dodanie stylowania containera dla stanów checked, focus
 * @see https://github.com/chakra-ui/chakra-ui/issues/4647
 */
export const baseStyle = props => {
  const { colorScheme: c } = props;
  const primaryColor = mode(`${c}.400`, `${c}.600`)(props);
  const secondaryColor = mode(`${c}.200`, `${c}.600`)(props);
  return {
    label: {
      fontWeight: 'medium',
      color: c === 'graphite' ? 'inherit' : primaryColor,
    },
    container: {
      borderColor: secondaryColor,
      cursor: 'pointer',
    },
    control: {
      borderColor: primaryColor,
      borderWidth: '1px',
      padding: '1px', // kompensacja za zmniejszenie borderWidth, inaczej ikony nie będą wyśrodkowane
      backgroundColor: 'transparent',
      _checked: {
        color: 'white',
        borderColor: primaryColor,
        backgroundColor: primaryColor,
        _hover: {
          borderColor: primaryColor,
          backgroundColor: primaryColor,
        },
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  };
};

export const variantGhost = props => {
  const { colorScheme: c, theme } = props;

  return {
    container: {
      borderRadius: 'sm',
      paddingX: '0.5em',
      paddingY: '.25em',
      bg: 'transparent',
      _hover: {
        bg: transparentize(`${c}.500`, 0.12)(theme),
      },
      _active: {
        bg: transparentize(`${c}.500`, 0.24)(theme),
      },
    },
  };
};

export const variantOutline = props => {
  const { container: ghostContainer } = variantGhost(props);
  return {
    container: {
      ...ghostContainer,
      borderWidth: '1px',
    },
  };
};

export const variants = {
  ghost: variantGhost,
  outline: variantOutline,
};

const sizes = {
  sm: {
    control: {
      width: '.8rem',
      height: '.8rem',
    },
    icon: {
      fontSize: '.5rem',
    },
    label: {
      fontSize: 'xs',
    },
  },
  md: {
    label: {
      fontSize: 'sm',
    },
    icon: {
      fontSize: '.5rem',
    },
  },
};

const defaultProps = {
  variant: 'outline',
  colorScheme: 'graphite',
  size: 'md',
};

export default {
  baseStyle,
  variants,
  sizes,
  defaultProps,
};
