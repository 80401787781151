import React from 'react';
import { useFormControl } from '@chakra-ui/react';

interface InputFileProps {
  acceptedFileTypes?: string;
  onChange: (value: File | null) => void;
}

export const InputFile = ({
  acceptedFileTypes,
  onChange,
  ...props
}: InputFileProps): JSX.Element => {
  const input = useFormControl(props);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    onChange(files ? files[0] : null);
  };

  return (
    <input
      {...input}
      type='file'
      accept={acceptedFileTypes}
      value={undefined}
      onChange={handleChange}
    />
  );
};
