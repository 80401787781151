import React, { Suspense, useEffect } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { Grid, GridItem } from '@chakra-ui/react';

import Header from 'components/Header';
import Sidebar from 'components/Sidebar';
import Router from 'components/Router';
import { EntityPageSkeletor } from 'components/Skeletons';
import { fetchAll as getConsts } from 'store/consts/consts.actions';
import { fetchAllAdSlots } from 'store/adslots';
import { fetchAllAdFormats } from 'store/adformats';
import { fetchAllClients } from 'store/clients';
import { fetchAllUsers } from 'store/users';
import { startConnecting } from 'store/notifications/notification.slice';

const OnAuthorizedLoad = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    batch(() => {
      dispatch(getConsts());
      dispatch(fetchAllAdSlots());
      dispatch(fetchAllAdFormats());
      dispatch(fetchAllClients());
      dispatch(fetchAllUsers());
      dispatch(startConnecting());
    });
  }, [dispatch]);

  return null;
};

function App() {
  const isLoggedIn = useSelector(state => state.user.isLoggedIn);
  return (
    <Grid
      h='100vh'
      templateAreas={`
            "sidebar header"
            "sidebar body"
          `}
      templateColumns='auto 1fr'
      templateRows='auto 1fr'
    >
      {isLoggedIn && (
        <>
          <OnAuthorizedLoad />
          <Sidebar gridArea='sidebar' />
          <Header gridArea='header' />
        </>
      )}
      <GridItem gridArea='body' minWidth={0} overflow='hidden'>
        <Suspense fallback={<EntityPageSkeletor />}>
          <Router />
        </Suspense>
      </GridItem>
    </Grid>
  );
}

export default App;
