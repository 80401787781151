import { createSelector } from 'reselect';
import {
  NAME,
  ENTITY_CAMPAIGN,
  ENTITY_LINEITEM,
  ENTITY_CREATIVE,
  ENTITY_PACKAGENAME,
  ENTITY_WEBSITE,
  ENTITY_OS,
  ENTITY_CITY,
  ENTITY_COORDINATE,
  ENTITY_EXCHANGE,
  ENTITY_BIDFLOOR,
  TIMEUNIT_TOTAL,
  TIMEUNIT_MONTH,
  TIMEUNIT_WEEK,
  TIMEUNIT_DAY,
  TIMEUNIT_HOUR,
  ENTITY_DEAL,
} from './statistics.consts';
import { selectCurrentCampaignId } from 'store/campaigns';
import adapter from './statistics.adapter';

const { selectAll } = adapter.getSelectors(state => state[NAME]);

export const selectAllCampaigns = createSelector(selectAll, all =>
  all.filter(stats => stats.entityType === ENTITY_CAMPAIGN),
);

export const selectAllLineitems = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_LINEITEM),
);

export const selectAllCreatives = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_CREATIVE),
);

export const selectAllApps = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_PACKAGENAME),
);

export const selectAllWebsites = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_WEBSITE),
);

export const selectAllOperatingsystems = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_OS),
);

export const selectAllCities = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_CITY),
);

export const selectAllCoordinates = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_COORDINATE),
);

export const selectAllExchanges = createSelector(selectAll, all =>
  all.filter(entity => entity.entityType === ENTITY_EXCHANGE),
);

export const selectAllBidfloors = createSelector(selectAll, all =>
  all.filter(stats => stats.entityType === ENTITY_BIDFLOOR),
);

export const selectAllDeals = createSelector(selectAll, all =>
  all.filter(stats => stats.entityType === ENTITY_DEAL),
);

export const selectTotalStatsForAllCampaigns = createSelector(
  selectAllCampaigns,
  allCampaigns =>
    allCampaigns.filter(campaign => campaign.timeunit === TIMEUNIT_TOTAL),
);

export const selectMonthlyStatsForAllCampaigns = createSelector(
  selectAllCampaigns,
  allCampaigns =>
    allCampaigns.filter(campaign => campaign.timeunit === TIMEUNIT_MONTH),
);

export const selectWeeklyStatsForAllCampaigns = createSelector(
  selectAllCampaigns,
  allCampaigns =>
    allCampaigns.filter(campaign => campaign.timeunit === TIMEUNIT_WEEK),
);

export const selectDailyStatsForAllCampaigns = createSelector(
  selectAllCampaigns,
  allCampaigns =>
    allCampaigns.filter(campaign => campaign.timeunit === TIMEUNIT_DAY),
);

export const selectHourlyStatsForAllCampaigns = createSelector(
  selectAllCampaigns,
  allCampaigns =>
    allCampaigns.filter(campaign => campaign.timeunit === TIMEUNIT_HOUR),
);

export const selectTotalStatsForCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectTotalStatsForAllCampaigns,
  (currentCampaignId, totalStatsForAllCampaigns) =>
    totalStatsForAllCampaigns.find(
      campaign => campaign[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || {},
);

export const selectMonthlyStatsForCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectMonthlyStatsForAllCampaigns,
  (currentCampaignId, monthlyStatsForAllCampaigns) =>
    monthlyStatsForAllCampaigns.filter(
      campaign => campaign[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectWeeklyStatsForCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectWeeklyStatsForAllCampaigns,
  (currentCampaignId, weeklyStatsForAllCampaigns) =>
    weeklyStatsForAllCampaigns.filter(
      campaign => campaign[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectDailyStatsForCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectDailyStatsForAllCampaigns,
  (currentCampaignId, dailyStatsForAllCampaigns) =>
    dailyStatsForAllCampaigns.filter(
      campaign => campaign[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectLastWeekStatsForCurrentCampaign = createSelector(
  selectDailyStatsForCurrentCampaign,
  dailyStatsForCurrentCampaign => dailyStatsForCurrentCampaign.slice(-7) || [],
);

export const selectHourlyStatsForCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectHourlyStatsForAllCampaigns,
  (currentCampaignId, hourlyStatsForAllCampaigns) =>
    hourlyStatsForAllCampaigns.filter(
      campaign => campaign[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectTotalStatsForAppsInCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllApps,
  (currentCampaignId, allApps) =>
    allApps.filter(app => app[ENTITY_CAMPAIGN] === currentCampaignId) || [],
);

export const selectTotalStatsForWebsitesInCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllWebsites,
  (currentCampaignId, allWebsites) =>
    allWebsites.filter(
      website => website[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectTotalStatsForOperatingsystemsInCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllOperatingsystems,
  (currentCampaignId, allOperatingsystems) =>
    allOperatingsystems.filter(
      os => os[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectTotalStatsForCitiesInCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllCities,
  (currentCampaignId, allCities) =>
    allCities.filter(city => city[ENTITY_CAMPAIGN] === currentCampaignId) || [],
);

export const selectTotalStatsForCoordinatesInCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllCoordinates,
  (currentCampaignId, allCoordinates) =>
    allCoordinates.filter(
      coordinate => coordinate[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);

export const selectTotalStatsForExchangesInCurrentCampaign = createSelector(
  selectCurrentCampaignId,
  selectAllExchanges,
  (currentCampaignId, allExchanges) =>
    allExchanges.filter(
      exchange => exchange[ENTITY_CAMPAIGN] === currentCampaignId,
    ) || [],
);
