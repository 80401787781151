import * as Yup from 'yup';
import {
  selectAdType,
  selectCreativeFormats,
  selectCreativeAttributes,
  selectGoogleAttributes,
  selectCreativeTemplateName,
} from 'store/consts';

const READ_ONLY_FIELDS = {
  id: Yup.number().integer().required(),
};

export const EDITABLE_FIELDS = {
  name: Yup.string().required().default('').label('Name'),
  format: Yup.number()
    .integer()
    .required()
    .default(1)
    .label('Format')
    .meta({ optionsSelector: selectCreativeFormats }),
  language: Yup.string().required().default('pl').label('Language'),
  adType: Yup.number()
    .integer()
    .required()
    .default(1)
    .label('Ad Type')
    .meta({ optionsSelector: selectAdType }),
  viewabilityMeasured: Yup.bool()
    .required()
    .default(true)
    .label('Viewability Measured'),
  mimeType: Yup.string().required().default('image/jpeg').label('MIME Type'),
  creativeAttributes: Yup.array(Yup.number().integer())
    .default([])
    .label('Creative Attributes')
    .meta({ optionsSelector: selectCreativeAttributes }),
  googleAttributes: Yup.array(Yup.number().integer())
    .default([])
    .label('Google Attributes')
    .meta({ optionsSelector: selectGoogleAttributes }),
  htmlMarkup: Yup.string().required().default('').label('HTML Markup'),
  templateId: Yup.number()
    .integer()
    .default(0)
    .label('Template ID')
    .meta({ optionsSelector: selectCreativeTemplateName }),
};

export const FORM_VALIDATION_SCHEMA = Yup.object().shape(EDITABLE_FIELDS);

export const creativeSchema = Yup.object().shape({
  ...READ_ONLY_FIELDS,
  ...EDITABLE_FIELDS,
});
