import * as React from 'react';
import { Textarea as ChakraTextarea, TextareaProps } from '@chakra-ui/react';

/**
 * Komponent Textarea Chakry dopasowany do standardu callbacku onChage, tj. przekazywana zostaje wartość, a nie cały event
 */
const Textarea = ({
  onChange,
  ...props
}: TextareaProps & { onChange: (value: string) => void }): JSX.Element => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (onChange) onChange(e.target.value);
  };

  return <ChakraTextarea onChange={handleChange} {...props} />;
};

export { Textarea };
