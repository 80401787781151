import * as React from 'react';
import {
  Text,
  Box,
  Heading,
  Stat,
  StatNumber,
  StatHelpText,
  StatArrow,
  StatArrowProps,
  StatGroup,
  Spinner,
  useStyleConfig,
  useToast,
} from '@chakra-ui/react';
import { useAppDispatch } from 'hooks';
import { useWatch } from 'react-hook-form';
import { withCurrentCampaignId } from 'hoc';
import { StatisticLabel } from 'components';
import { CampaignId } from 'components/Campaign';
import { fetchLineitemFormStatisticsForecast } from 'store/lineitems';
import { formatStatisticValue, NUMBER, getPercentageIncrease } from 'helpers';

type StatisticsForecast = {
  [key: string]: number | undefined;
};

type StatisticsForecastState = {
  current: StatisticsForecast;
  previous: StatisticsForecast;
};

/**
 * forecats statystyk na podstawie bieżących ustawień pól formularza
 * by ograniczyć liczbę zapytań do api, jeżeli w trakcie bieżącego zapytania zostanie wykryta zmiana w formularzu, przestawiana jest flaga shouldFetchAgain
 * flaga jest weryfikowana po wykonaniu zapytania, gdzie w razie potrzeby inicjowane jest kolejne zapytanie
 */
export const LineitemFormStatisticsForecast = ({
  campaignId,
}: CampaignId): JSX.Element => {
  const style = useStyleConfig('Card', { size: 'sm' });
  const dispatch = useAppDispatch();
  const formData = useWatch();
  const [isFetching, setIsFetching] = React.useState(false);
  const [shouldFetchAgain, setShouldFetchAgain] = React.useState(false);
  const [
    statisticsForecastState,
    setStatisticsForecastState,
  ] = React.useState<StatisticsForecastState>({
    current: {
      impressions: undefined,
      clicks: undefined,
      potential: undefined,
    },
    previous: {
      impressions: undefined,
      clicks: undefined,
      potential: undefined,
    },
  });

  const toast = useToast({
    position: 'bottom',
    status: 'error',
    isClosable: true,
  });

  const fetchFormStatisticsForecast = async () => {
    if (isFetching) return;
    setIsFetching(true);
    const response = await dispatch(
      fetchLineitemFormStatisticsForecast({
        campaignId,
        formData,
      }),
    );

    setIsFetching(false);

    /**
     * dane pobrane prawidłowo
     */
    if (response.meta.requestStatus === 'fulfilled') {
      setStatisticsForecastState((state: StatisticsForecastState) => {
        return {
          previous: state.current,
          current: response.payload.data,
        };
      });
      return;
    }

    /**
     * błąd http; status === 0 oznacza anulowanie requestu, nie chcemy wtedy pokazywać komunikatu błędu
     */
    if (response.payload.status) {
      toast({
        title: 'Error during prediction calculation',
        description: response?.payload?.data?.message || 'Something went wrong',
      });
    }
  };

  React.useEffect(() => {
    if (!campaignId || !formData) return;

    if (isFetching) {
      if (!shouldFetchAgain) {
        setShouldFetchAgain(true);
      }
      return;
    }

    fetchFormStatisticsForecast();
  }, [campaignId, formData]);

  React.useEffect(() => {
    if (shouldFetchAgain && !isFetching) {
      setShouldFetchAgain(false);
      fetchFormStatisticsForecast();
    }
  }, [statisticsForecastState, shouldFetchAgain, isFetching]);

  return (
    <Box __css={style}>
      <Heading size='md'>
        Available traffic forecast
        {isFetching && <Spinner size='sm' ml={2} />}
      </Heading>
      <Text fontSize='xs'>Daily estimations</Text>
      <StatGroup mt={2}>
        {Object.entries(statisticsForecastState.current).map(([key, value]) => {
          let trendValue = 0;
          let trendType: StatArrowProps['type'];

          const previousValue: number | undefined =
            statisticsForecastState?.previous[key];
          if (value && previousValue) {
            trendValue = getPercentageIncrease(value, previousValue);
            trendType = trendValue > 0 ? 'increase' : 'decrease';
          }

          return (
            <Stat key={key}>
              <StatisticLabel statisticKey={key} />
              <StatNumber>{formatStatisticValue(key, value)}</StatNumber>
              <StatHelpText>
                <StatArrow type={trendType} />
                {NUMBER.format(trendValue)}%
              </StatHelpText>
            </Stat>
          );
        })}
      </StatGroup>
    </Box>
  );
};

export const LineitemOfCurrentCampaignFormStatisticsForecast = withCurrentCampaignId(
  LineitemFormStatisticsForecast,
);
