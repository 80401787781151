import { createSelector } from 'reselect';
import { NAME } from './settings.consts';

const settingsSelector = state => state[NAME];

export const selectUserSettings = createSelector(
  state => state.settingsSelector,
);

export const selectDarkMode = createSelector(
  settingsSelector,
  settings => settings.darkMode,
);

export const selectIsSidebarCollapsed = createSelector(
  settingsSelector,
  settings => settings.sidebarIsCollapsed,
);
