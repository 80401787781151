import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  HStack,
  VStack,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  Tooltip,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItemOption,
  MenuOptionGroup,
  Button,
  ButtonGroup,
  useBoolean,
  MenuDivider,
} from '@chakra-ui/react';
import {
  ComposedChart,
  Bar,
  LabelList,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartTooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { FiChevronDown, FiRefreshCcw, FiLayout } from 'react-icons/fi';
import { ChartTooltip, ChartLegend } from 'components';
import Card from 'layouts/Card';
import {
  fetchBidfloorsForLineitem,
  selectAllBidfloors,
  STAT_TRAFFICAFFORDABLE,
  STAT_TRAFFICNOTAFFORDABLE,
  STAT_MEDIANFLOORPRICE,
  NAMES,
  DEFINITIONS,
} from 'store/statistics';
import { useAppSelector } from 'hooks';
import { withCurrentLineitemId } from 'hoc';
import { formatStatisticValue } from 'helpers';
import { selectExchanges, OptionsArray } from 'store/consts';
import { StatSkeleton } from 'components/Skeletons';

type Pricing = {
  trafficPercentAvailable: number;
  medianPriceFloor: number;
  trafficPercentAffordable: number;
};

type BidfloorStatistic = {
  entityType: string;
  id: string;
  lineitemId: number;
  type: string;
  medianPriceFloor: number;
  trafficPercentAffordable: number;
  creativeFormats: Array<
    {
      format: string;
      exchanges: ExchangePricing[];
    } & Pricing
  >;
  creatives: Array<
    {
      creativeName: string;
      exchanges: ExchangePricing[];
    } & Pricing
  >;
  total: ExchangePricing[];
};

type ExchangePricing = {
  exchange: string;
} & Pricing;

type BidfloorContextProps = {
  setCurrentChartOption?: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  currentChartOption?: string;
  chartOptions?: ChartDataUnit[];
  medianPriceFloor?: number;
  trafficPercentAffordable?: number;
  type?: string;
  isFetching?: boolean;
  fetchData?: () => Promise<void>;
};

type ChartDataUnit = {
  name: string;
  key: string;
  data: Array<{
    exchange: string;
    affordableTraffic: number;
    notAffordableTraffic: number;
    medianPriceFloor: number;
  }>;
};

const BidfloorContext = React.createContext<BidfloorContextProps>({});

export const LineitemBidfloor = ({
  lineitemId,
}: {
  lineitemId: number;
}): JSX.Element | null => {
  const dispatch = useDispatch();
  /**
   * Lista exchange z const; wykorzystywane do wyświetlenia nazwy przyjaznej exchange w wykresie
   */
  const exchanges = useAppSelector(selectExchanges);
  /**
   * klucz bieżąco wybranego zestawu danych
   */
  const [currentChartOption, setCurrentChartOption] = React.useState<
    string | undefined
  >();
  /**
   * flaga raportująca, czy są aktualnie pobierane dane przez fetchData()
   */
  const [isFetching, setIsFetching] = useBoolean(false);

  /**
   * tablica statystyk wszystkich bidfloorów
   */
  const allBidfloors: BidfloorStatistic[] | undefined = useSelector(
    selectAllBidfloors,
  );

  /**
   * obiekt statystyk bidfloora dla wybranego lineitema
   */
  const lineitemBidfloor: BidfloorStatistic | undefined = React.useMemo(() => {
    if (!allBidfloors || !lineitemId) return;
    return allBidfloors.find(bidfloor => bidfloor?.lineitemId === lineitemId);
  }, [allBidfloors, lineitemId]);

  /**
   * tablica z danymi bidflorów dla określonych zbiorów danych (obecnie: total, formaty kreacji); każdy zbiór dodatkowo zawiera podział na exchange
   */
  const chartOptions: ChartDataUnit[] | [] = React.useMemo(() => {
    if (!lineitemBidfloor) return [];
    const total: ChartDataUnit = {
      name: 'Total',
      key: 'total',
      data: lineitemBidfloor.total.map(exchange =>
        calculateExchangeUnitData(exchanges, exchange),
      ),
    };
    const creativeFormats = lineitemBidfloor?.creativeFormats.map(
      creativeFormat => {
        return {
          name: creativeFormat.format,
          key: creativeFormat.format + '_format',
          data: creativeFormat?.exchanges.map(exchange =>
            calculateExchangeUnitData(exchanges, exchange),
          ),
        };
      },
    );
    const creatives = lineitemBidfloor?.creatives.map(creatives => {
      return {
        name: creatives.creativeName,
        key: creatives.creativeName + '_creative',
        data: creatives?.exchanges.map(exchenge =>
          calculateExchangeUnitData(exchanges, exchenge),
        ),
      };
    });
    /**
     * Do zmiany
     */
    const divider = {
      name: '',
      key: '',
      data: [
        {
          exchange: '',
          affordableTraffic: 0,
          notAffordableTraffic: 0,
          medianPriceFloor: 0,
        },
      ],
    };

    return [total, divider, ...creativeFormats, divider, ...creatives];
  }, [lineitemBidfloor, exchanges]);

  const fetchData = async () => {
    if (!lineitemId) return;
    setIsFetching.on();
    await dispatch(fetchBidfloorsForLineitem(lineitemId));
    setIsFetching.off();
  };

  React.useEffect(() => {
    if (lineitemId) fetchData();
  }, [lineitemId]);

  React.useEffect(() => {
    if (chartOptions?.length && !currentChartOption) {
      setCurrentChartOption(chartOptions[0].key);
    }
  }, [chartOptions, currentChartOption]);

  if (!lineitemBidfloor && !isFetching) return null;

  return (
    <BidfloorContext.Provider
      value={{
        setCurrentChartOption,
        currentChartOption,
        chartOptions,
        type: lineitemBidfloor?.type,
        medianPriceFloor: lineitemBidfloor?.medianPriceFloor,
        trafficPercentAffordable: lineitemBidfloor?.trafficPercentAffordable,
        isFetching,
        fetchData,
      }}
    >
      <Container />
    </BidfloorContext.Provider>
  );
};

function calculateExchangeUnitData(
  exchanges: OptionsArray,
  exchange: ExchangePricing,
): {
  exchange: string;
  affordableTraffic: number;
  notAffordableTraffic: number;
  medianPriceFloor: number;
} {
  const {
    exchange: exchangeId,
    trafficPercentAvailable,
    medianPriceFloor,
    trafficPercentAffordable,
  } = exchange;
  const exchangeName =
    exchanges.find(exchange => exchangeId === exchange.id)?.name || exchangeId;

  return {
    exchange: exchangeName,
    medianPriceFloor: medianPriceFloor,
    affordableTraffic: trafficPercentAvailable * trafficPercentAffordable,
    notAffordableTraffic:
      trafficPercentAvailable * (1 - trafficPercentAffordable),
  };
}

/**
 * Kontener komponentu, zawierający bazowe ułożenie subkomponentów
 */
const Container = () => (
  <Card size='sm' variant='default' maxWidth='container.lg'>
    <Header />
    <HStack spacing={4} align='self-start'>
      <LineitemBidfloorStat />
      <CreativeFormatsChart />
    </HStack>
  </Card>
);

/**
 * Nagłówek nad wykresem; zawiera tytuł oraz bazowe menu
 */
const Header = () => (
  <HStack marginBottom={6}>
    <Heading lineHeight={1} flexGrow={1}>
      {NAMES[STAT_TRAFFICAFFORDABLE]}
      <Box as='small' fontSize='md' marginLeft={2}>
        for the last 24h
      </Box>
    </Heading>
    <ButtonGroup>
      <RefreshDataButton />
      <CreativeFormatMenu />
    </ButtonGroup>
  </HStack>
);

/**
 * Przycisk odświeżenia danych
 */
const RefreshDataButton = () => {
  const { isFetching, fetchData } = React.useContext(BidfloorContext);
  return (
    <Tooltip label='Refresh data' hasArrow placement='top' openDelay={500}>
      <span>
        <IconButton
          aria-label='Refresh data'
          icon={<FiRefreshCcw />}
          isLoading={isFetching}
          onClick={fetchData}
          size='sm'
        />
      </span>
    </Tooltip>
  );
};

/**
 * Menu wyboru rodzaju danych (total, formaty reklamowe)
 */
const CreativeFormatMenu = () => {
  const {
    isFetching,
    setCurrentChartOption,
    currentChartOption,
    chartOptions,
  } = React.useContext(BidfloorContext);

  const handleMenuChange = (value: string | string[]) => {
    const key: string = typeof value === 'object' ? value[0] : value;
    if (setCurrentChartOption) setCurrentChartOption(key);
  };

  const chartOptionName = React.useMemo(() => {
    if (!chartOptions || !currentChartOption) return;
    const currentChartOptionName = chartOptions.find(
      format => format.key === currentChartOption,
    );
    return currentChartOptionName?.name;
  }, [chartOptions, currentChartOption]);

  return (
    <Box>
      <Menu>
        <MenuButton
          as={Button}
          isDisabled={isFetching}
          leftIcon={<FiLayout />}
          rightIcon={<FiChevronDown />}
          textAlign='left'
          size='sm'
          matchwidth='true'
        >
          {chartOptionName}
        </MenuButton>
        <MenuList>
          <MenuOptionGroup
            type='radio'
            value={currentChartOption}
            onChange={handleMenuChange}
          >
            {chartOptions?.map(({ key, name }) => {
              {
                return name.length === 0 ? (
                  <MenuDivider />
                ) : (
                  <MenuItemOption key={key} value={key}>
                    {name}
                  </MenuItemOption>
                );
              }
            })}
          </MenuOptionGroup>
        </MenuList>
      </Menu>
    </Box>
  );
};

/**
 * Zbiorcza statystyka lineitema, wyświetlana jako dwa <Stat /> jeden pod drugim
 */
const LineitemBidfloorStat = () => {
  const {
    type,
    trafficPercentAffordable,
    medianPriceFloor,
    isFetching,
  } = React.useContext(BidfloorContext);

  const stats: Array<{
    key: typeof STAT_TRAFFICAFFORDABLE | typeof STAT_MEDIANFLOORPRICE;
    value: number | undefined;
  }> = [
    {
      key: STAT_TRAFFICAFFORDABLE,
      value: trafficPercentAffordable,
    },
    {
      key: STAT_MEDIANFLOORPRICE,
      value: medianPriceFloor,
    },
  ];

  if (!trafficPercentAffordable && isFetching)
    return <StatSkeleton size='xl' />;

  return (
    <VStack spacing={4} align='flex-start'>
      {stats.map(stat => (
        <Tooltip
          key={stat.key}
          label={DEFINITIONS[stat.key]}
          hasArrow
          placement='top'
          openDelay={500}
        >
          <span>
            <Stat size='xl'>
              <StatNumber>
                {formatStatisticValue(stat.key, stat.value)}
              </StatNumber>
              <StatLabel borderBottomWidth='1px' cursor='help'>
                {NAMES[stat.key]}
                {stat.key === STAT_MEDIANFLOORPRICE && (
                  <Box as='small' marginLeft={1}>
                    ({type})
                  </Box>
                )}
              </StatLabel>
            </Stat>
          </span>
        </Tooltip>
      ))}
    </VStack>
  );
};

/**
 * Bazowe formatowanie kwot na potrzeby wykresu
 */
function handlePriceFormatting(value: number) {
  return (
    Intl.NumberFormat('pl-PL', {
      maximumSignificantDigits: 4,
    }).format(value) + '$'
  );
}

/**
 * Bazowe formatowanie (ułamek jako procent) na potrzeby wykresu
 */
function handlePercentageFormatting(value: number) {
  return value * 100 + '%';
}

/**
 * Wysokość wykresu w pikselach
 */
const CHART_HEIGHT = 200;

/**
 * Wysokość wykresu w pikselach
 */
const CreativeFormatsChart = () => {
  const { chartOptions, currentChartOption } = React.useContext(
    BidfloorContext,
  );

  const chartData = React.useMemo(() => {
    if (!chartOptions || !currentChartOption) return;
    const currentChartOptionData = chartOptions.find(
      format => format.key === currentChartOption,
    );
    return currentChartOptionData?.data;
  }, [chartOptions, currentChartOption]);

  return (
    <Box width='100%' height={`${CHART_HEIGHT}px`} position='relative'>
      <ResponsiveContainer width='100%' height={CHART_HEIGHT}>
        <ComposedChart
          data={chartData}
          margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
        >
          <defs>
            <pattern
              id='diagonalHatch'
              width='6'
              height='6'
              patternTransform='rotate(45 0 0)'
              patternUnits='userSpaceOnUse'
            >
              <rect
                x='0'
                y='0'
                width='6'
                height='6'
                style={{
                  fill: '#003f5c',
                  fillOpacity: 0.2,
                }}
              />
              <line
                x1='0'
                y1='0'
                x2='0'
                y2='6'
                style={{
                  stroke: '#003f5c',
                  strokeWidth: 2,
                }}
              />
            </pattern>
          </defs>
          <CartesianGrid strokeDasharray='1 1' />
          <XAxis dataKey='exchange' />
          <YAxis
            yAxisId='price'
            orientation='right'
            tickFormatter={handlePriceFormatting}
            tickCount={3}
          />
          <YAxis
            yAxisId='traffic'
            tickFormatter={handlePercentageFormatting}
            ticks={[0, 0.5, 1]}
          />
          <RechartTooltip
            content={<ChartTooltip />}
            isAnimationActive={false}
            active={true}
            allowEscapeViewBox={{ x: true, y: true }}
            offset={0}
          />
          <Legend content={<ChartLegend />} />
          <Bar
            yAxisId='traffic'
            stackId='traffic'
            dataKey={STAT_TRAFFICAFFORDABLE}
            name={NAMES[STAT_TRAFFICAFFORDABLE]}
            barSize={30}
            isAnimationActive={false}
            fill='#008e5f'
          />
          <Bar
            yAxisId='traffic'
            stackId='traffic'
            dataKey={STAT_TRAFFICNOTAFFORDABLE}
            name={NAMES[STAT_TRAFFICNOTAFFORDABLE]}
            barSize={30}
            isAnimationActive={false}
            fill='url(#diagonalHatch)'
          />
          <Scatter
            yAxisId='price'
            dataKey={STAT_MEDIANFLOORPRICE}
            name={NAMES[STAT_MEDIANFLOORPRICE]}
            isAnimationActive={false}
            fill='#ffa600'
          >
            <LabelList
              dataKey={STAT_MEDIANFLOORPRICE}
              position='top'
              fill='#ffa600'
              formatter={handlePriceFormatting}
              style={{
                fontWeight: 'bold',
                textShadow: 'rgb(0 0 0 / 20%) 1px 1px 1px',
              }}
            />
          </Scatter>
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
};

export const CurrentLineitemBidfloor = withCurrentLineitemId(LineitemBidfloor);
