import { selectAllCampaigns, selectCurrentCampaignId } from 'store/campaigns';
import { createSelector } from 'reselect';
import { RootState } from 'store';
import adapter from './proposals.adapter';
import { ProposalParams } from './proposals.types';
import {
  REDUCER_KEY,
  DRAFT,
  BUYER_REVIEW_REQUESTED,
  PUBLISHER_REVIEW_REQUESTED,
  BUYER_ACCEPTANCE_REQUESTED,
  TERMINATED,
} from './proposals.consts';
import { normalizeDateFromApi } from 'helpers/datetimeHelpers';

const selectors = adapter.getSelectors(
  (state: RootState) => state[REDUCER_KEY],
);
export const { selectIds, selectTotal, selectAll } = selectors;

export const selectById = (
  state: RootState,
  id: number,
): ProposalParams | null => selectors.selectById(state, id) || null;

export const selectCurrentProposalId = (state: RootState): number =>
  state[REDUCER_KEY].current;

export const selectAllProposals = createSelector(
  state => selectors.selectAll(state),
  finalizedProposals => finalizedProposals || [],
);

export const selectNewProposals = createSelector(
  state => selectors.selectAll(state),
  finalizedProposals =>
    finalizedProposals.filter(({ status }) => status === DRAFT) || [],
);

export const selectDeletedProposals = createSelector(
  state => selectors.selectAll(state),
  finalizedProposals =>
    finalizedProposals.filter(({ status }) => status === TERMINATED) || [],
);

export const selectSendedProposals = createSelector(
  state => selectors.selectAll(state),
  finalizedProposals =>
    finalizedProposals.filter(
      ({ status }) => status === PUBLISHER_REVIEW_REQUESTED,
    ) || [],
);

export const selectrReviewRequestedProposals = createSelector(
  state => selectors.selectAll(state),
  finalizedProposals =>
    finalizedProposals.filter(
      ({ status }) =>
        status === BUYER_REVIEW_REQUESTED ||
        status === BUYER_ACCEPTANCE_REQUESTED,
    ) || [],
);

export const selectCurrentProposal = createSelector(
  state => selectCurrentProposalId(state),
  state => selectAll(state),
  (currentProposalId, allProposals) =>
    allProposals.find(({ id }) => id === currentProposalId),
);

export function selectProposal(
  state: RootState,
  dealId: number,
): ProposalParams | undefined {
  return selectors.selectById(state, dealId) || undefined;
}

export const selectCampaignsOfCurrentProposal = createSelector(
  selectCurrentProposalId,
  selectAllCampaigns,
  (currentProposalId, allCampaigns) =>
    allCampaigns.filter(campaign => campaign.proposal === currentProposalId) ||
    [],
);

export const selectProposalsOfCurrentCampaign = createSelector(
  selectAllProposals,
  selectCurrentCampaignId,
  (allProposals, campaignId) =>
    allProposals.filter(proposal => proposal.campaign?.id === campaignId) || [],
);

export const selectCurrentProposalStatus = createSelector(
  selectCurrentProposal,
  proposal => proposal?.status,
);

export const selectProposalStatusById = (
  state: RootState,
  proposalId: number,
): string | undefined => {
  const proposal = selectProposal(state, proposalId);
  return proposal?.status;
};

export const selectCurrentProposalCampaignDates = createSelector(
  selectCurrentProposal,
  proposal => {
    if (!proposal) {
      return null;
    }
    return {
      startDate: normalizeDateFromApi(proposal.campaign?.startDate) || null,
      endDate: normalizeDateFromApi(proposal.campaign?.endDate) || null,
    };
  },
);

export const selectCurrentProposalPricingModel = createSelector(
  selectCurrentProposal,
  proposal => proposal?.generalProductValue?.pricingModel,
);

export const selectProposalsOfBasket = (
  state: RootState,
  basketId: number,
): ProposalParams[] =>
  selectAllProposals(state).filter(proposal => proposal.basket === basketId);
