import { mode } from '@chakra-ui/theme-tools';
export const TableStyles = {
  baseStyle: {
    table: {},
    tr: {},
    th: {},
    td: {},
    caption: {},
  },
  variants: {
    // simple: props => ({
    //   table: {
    //     borderRadius: 'lg',
    //     overflow: 'hidden',
    //   },
    //   th: {
    //     p: 4,
    //     border: '1px',
    //     borderColor: mode('graphite.200', 'graphite.500')(props),
    //     background: mode('', 'graphite.500')(props),
    //   },
    //   tr: {
    //     background: mode('graphite.100', 'graphite.300')(props),
    //   },
    //   td: {
    //     p: 4,
    //     borderRadius: 0,
    //     border: '1px',
    //     borderColor: mode('graphite.200', 'graphite.500')(props),
    //     background: mode('white.100', 'graphite.200')(props),
    //   },
    //   caption: {},
    // }),
    main: props => ({
      table: {
        borderRadius: 'lg',
        overflow: 'hidden',
      },
      th: {
        p: 4,
        border: '1px',
        borderColor: mode('graphite.200', 'graphite.600')(props),
        background: mode('', 'graphite.500')(props),
      },
      tr: {
        background: mode('graphite.100', 'graphite.300')(props),
      },
      td: {
        p: 4,
        borderRadius: 0,
        border: '1px',
        borderColor: mode('graphite.200', 'graphite.600')(props),
        background: mode('white.100', 'graphite.200')(props),
      },
      caption: {},
    }),
  },
};
