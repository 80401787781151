import * as React from 'react';
import { RootState } from 'store';
import { isBefore, startOfToday } from 'date-fns';
import { Tooltip } from '@chakra-ui/react';
import { useAppSelector } from 'hooks';
import { StatusIcon } from 'components';
import { selectCampaign } from 'store/campaigns';
import { selectLineitem } from 'store/lineitems';
import { LineitemInterface } from './Lineitem.schema';

const FINISHED = 'finished';
const ACTIVE = 'active';
const INACTIVE = 'inactive';

const STATUS = {
  [FINISHED]: { color: 'purple.400', message: 'Finished' },
  [ACTIVE]: { color: 'green.400', message: 'Active' },
  [INACTIVE]: { color: 'red.400', message: 'Inactive' },
};

export const LineitemStatusIcon = ({
  lineitemId,
}: {
  lineitemId: number;
}): JSX.Element | null => {
  const lineitem = useAppSelector(
    (state: RootState): LineitemInterface => selectLineitem(state, lineitemId),
  );
  const campaign = useAppSelector((state: RootState) =>
    selectCampaign(state, lineitem.campaignId),
  );

  const lineitemStatus = React.useMemo(() => {
    if (isBefore(lineitem.general.timing.endDate, startOfToday()))
      return FINISHED;
    if (lineitem.status.isActive) return ACTIVE;
    return INACTIVE;
  }, [lineitem.status.isActive, lineitem.general.timing.endDate]);

  if (!lineitem || !lineitem.status || !campaign) return null;

  if (!campaign.status.isActive) {
    return (
      <Tooltip label='Campaign is not active' placement='top' hasArrow>
        <span>
          <StatusIcon
            color={'gray.400'}
            message={STATUS[lineitemStatus].message}
            isUnderlined
          />
        </span>
      </Tooltip>
    );
  }

  return (
    <StatusIcon
      color={STATUS[lineitemStatus].color}
      message={STATUS[lineitemStatus].message}
    />
  );
};
