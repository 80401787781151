import React from 'react';
import { Box, useStyleConfig } from '@chakra-ui/react';

export interface FieldsetProps {
  size?: string;
  variant?: string;
  children: React.ReactNode;
}

export const Fieldset = ({
  children,
  size,
  variant,
  ...props
}: FieldsetProps): JSX.Element => {
  const style = useStyleConfig('Fieldset', { size, variant });
  return (
    <Box __css={style} {...props}>
      {children}
    </Box>
  );
};
