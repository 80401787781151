import * as React from 'react';
import { Button, Box, Grid, Flex, IconButton, Tooltip } from '@chakra-ui/react';
import { FiTrash2, FiPlus } from 'react-icons/fi';

interface InputArrayProps {
  component: React.ElementType;
  inputValues: Array<any>;
  isDisabled?: boolean;
  isExpandable?: boolean;
  isTime?: boolean;
  templateColumns?: string;
  onCreate: () => void;
  onDelete: (index: number) => void;
  onChange: (value: any, index: number) => void;
  [key: string]: any;
}

const InputArray = ({
  component: Component,
  inputValues,
  isDisabled,
  isExpandable,
  isTime,
  onCreate,
  onDelete,
  onChange,
  templateColumns,
  ...props
}: InputArrayProps): JSX.Element => {
  return (
    <Grid gap={2} templateColumns={templateColumns}>
      {inputValues?.map((value, index) => {
        return (
          <Flex key={index} gap={3}>
            <Component
              key={index}
              value={value ? value : typeof value === 'number' ? 0 : ''}
              onChange={(value: any) => {
                if (typeof value === 'object' && value !== null) {
                  value = value.target.value;
                }
                onChange(value, index);
              }}
              {...props}
            />
            {isExpandable && (
              <Tooltip label='Remove this' hasArrow placement='top'>
                <span>
                  <IconButton
                    aria-label='Remove this'
                    icon={<FiTrash2 />}
                    onClick={() => onDelete(index)}
                    isDisabled={isDisabled}
                  />
                </span>
              </Tooltip>
            )}
          </Flex>
        );
      })}
      {isTime && <Box fontSize='xs'>Timezone: UTC</Box>}
      {isExpandable && (
        <Box>
          <Button
            leftIcon={<FiPlus />}
            onClick={onCreate}
            isDisabled={isDisabled}
          >
            Add
          </Button>
        </Box>
      )}
    </Grid>
  );
};

interface InputArrayWithReactHookFormProps {
  component: React.ElementType;
  [key: string]: any;
  value: Array<any>;
  onChange: (value: Array<any>) => void;
}

const InputArrayWithReactHookForm = ({
  component: Component,
  value: arrayOfValues,
  onChange: setArrayOfValues,
  ...props
}: InputArrayWithReactHookFormProps): JSX.Element => {
  const handleInputCreate = () => setArrayOfValues(arrayOfValues.concat(''));

  const handleInputChange = (modifiedValue: any, indexToModify: number) => {
    setArrayOfValues(
      arrayOfValues.map((value: any, index: number) => {
        if (index !== indexToModify) return value;
        return modifiedValue;
      }),
    );
  };

  const handleInputDelete = (indexToRemove: number) =>
    setArrayOfValues(
      arrayOfValues.filter((_: any, index: number) => index !== indexToRemove),
    );

  return (
    <InputArray
      component={Component}
      onChange={handleInputChange}
      onCreate={handleInputCreate}
      onDelete={handleInputDelete}
      inputValues={arrayOfValues}
      {...props}
    />
  );
};

export { InputArray, InputArrayWithReactHookForm };
