import * as React from 'react';
import { AxiosResponse } from 'axios';
import { Box, UnorderedList, ListItem } from '@chakra-ui/react';

const ERROR_CODES: { [key: string]: string } = {
  400: 'Bad Request',
  401: 'Unauthorized',
  402: 'Payment Required',
  403: 'Forbidden',
  404: 'Not Found',
  405: 'Method Not Allowed',
  500: 'Internal Server Error',
  501: 'Not Implemented',
  502: 'Bad Gateway',
  503: 'Service Unavailable',
  504: 'Gateway Timeout',
};

/**
 * przekształca dowolną odpowiedź z błędem przychodzącą z api do postaci przyjaznego userowi komunikatu
 * @param errors dowolny obiekt błędu z api
 * @returns JSX.Element
 */
export function formatApiErrors(response: AxiosResponse): JSX.Element {
  if (!response.data.message) {
    return (
      <Box>
        {response.status} {ERROR_CODES[response.status]}
      </Box>
    );
  }

  const arrayOfErrorStrings = handleErrorObject(response.data.message);

  return (
    <UnorderedList>
      {arrayOfErrorStrings.map((error, index) => (
        <ListItem key={index}>{error}</ListItem>
      ))}
    </UnorderedList>
  );
}

function handleErrorObject(errors: any): string[] {
  const arrayOfErrorStrings: string[] = [];

  /**
   * obiekt jest tablicą; traktujemy całość jako tablicę stringów
   * api nie powinno zwrócić w tablicy innych wartości, ale na wszelki wypadek korzystamy z toString()
   */
  if (Array.isArray(errors)) {
    for (const error of errors) {
      arrayOfErrorStrings.push(error.toString());
    }
    return arrayOfErrorStrings;
  }

  /**
   * obiekt z kluczami; wartością może być dalszy obiekt, tablica stringów lub string
   * lecimy pętlą po kluczach i konwertujemy wartość przez rekurencję bieżącej funkcji, ponieważ obiekt może być zagnieżdzony teoretycznie w nieskończoność
   */
  if (typeof errors === 'object') {
    for (const error of Object.values(errors)) {
      arrayOfErrorStrings.push(...handleErrorObject(error));
    }
    return arrayOfErrorStrings;
  }

  /**
   * dla pozostałych przypadków zwracamy zawartość przekonwertowaną do postaci stringa
   */
  arrayOfErrorStrings.push(errors.toString());
  return arrayOfErrorStrings;
}
