import * as React from 'react';
import { Box, Tooltip, useStyleConfig } from '@chakra-ui/react';

export type AbbrProps = {
  text: string | number;
  tooltip: string | number;
};

export const Abbr = ({ text, tooltip }: AbbrProps): JSX.Element => {
  const style = useStyleConfig('Abbr');
  return (
    <Tooltip hasArrow placement='top' label={tooltip.toString()}>
      <Box as='span' __css={style}>
        {text}
      </Box>
    </Tooltip>
  );
};
