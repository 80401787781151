import { BasketParams } from './baskets.types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import adserver from 'services/adserver';
import { withErrorHandling } from 'store/wrappers';
import { REDUCER_KEY } from './baskets.consts';
import { selectCurrentCampaignId } from 'store/campaigns';

export const fetchAllBasketsOfCampaign = createAsyncThunk(
  REDUCER_KEY + '/get',
  withErrorHandling(async ({ campaignId }: { campaignId: number }) => {
    const response = await adserver({
      url: `/campaign/${campaignId}/baskets`,
      headers: {},
    });

    return response.data.map((basket: BasketParams) => ({
      ...basket,
      campaignId,
    }));
  }),
);

export const fetchAllBasketsForCurrentCampaign = createAsyncThunk(
  REDUCER_KEY + '/fetchAllBasketsForCurrentCampaign',
  withErrorHandling(async (_: any, thunkApi: any) => {
    const campaignId = selectCurrentCampaignId(thunkApi.getState());
    const response = await adserver({
      url: `/campaign/${campaignId}/baskets`,
      headers: {},
    });

    return response.data.map((basket: BasketParams) => ({
      ...basket,
      campaignId,
    }));
  }),
);

export const submit = createAsyncThunk(
  REDUCER_KEY + '/update',
  withErrorHandling(async (basket: BasketParams) => {
    const response = await adserver({
      url: `/basket/${basket.id}/order/submit`,
      method: 'POST',
      headers: {},
      data: basket,
    });
    return response.data;
  }),
);

export const accept = createAsyncThunk(
  REDUCER_KEY + '/accept',
  withErrorHandling(async (basketId: number) => {
    const response = await adserver({
      url: `/basket/${basketId}/order/accept`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const rectify = createAsyncThunk(
  REDUCER_KEY + '/rectify',
  withErrorHandling(async (basketId: number) => {
    const response = await adserver({
      url: `/basket/${basketId}/order/rectify`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const generateOrder = createAsyncThunk(
  REDUCER_KEY + '/generate_order',
  withErrorHandling(async (basketId: number) => {
    const response = await adserver({
      url: `/basket/${basketId}/order/generate_order`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const cancel = createAsyncThunk(
  REDUCER_KEY + '/cancel',
  withErrorHandling(async (basketId: number) => {
    const response = await adserver({
      url: `/basket/${basketId}/order/cancel`,
      method: 'POST',
      headers: {},
    });
    return response.data;
  }),
);

export const removeAllBaskets = (): { type: string } => ({
  type: REDUCER_KEY + '/removeAll',
});
