import * as React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useAppSelector } from 'hooks';
import { ControlledSelect } from 'components';

const LineitemGeneralFrequencyCappingPeriod = ({
  ...props
}: {
  [key: string]: any;
}): JSX.Element => {
  const periods = useAppSelector(state => state.consts.frequencyCappingPeriod);

  const options = React.useMemo(() => {
    return periods?.map(({ id, name }: { id: string; name: string }) => ({
      id,
      name,
    }));
  }, [periods]);

  const [isDisabled, setIsDisabled] = React.useState(false);
  const { control } = useFormContext();
  const {
    field: { value: isActive },
  } = useController({
    name: 'general.frequencyCapping.enabled',
    control,
  });

  React.useEffect(() => {
    setIsDisabled(!isActive);
  }, [isActive]);

  return (
    <ControlledSelect
      name='general.frequencyCapping.period'
      label='Period'
      options={options}
      isDisabled={isDisabled}
      {...props}
    />
  );
};

LineitemGeneralFrequencyCappingPeriod.displayName =
  'Lineitem.general.frequencyCapping.period';

export { LineitemGeneralFrequencyCappingPeriod };
