import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { Link, generatePath } from 'react-router-dom';
import { CREATIVE_CREATE, CREATIVE_SHOW, CREATIVE_EDIT } from 'routes';
import { withCurrentCreativeId } from 'hoc';
import { useAppSelector } from 'hooks';
import { selectCreative } from 'store/creatives';
import { CreativeIdProps, CreativeObjectProps } from './Creative.types';
import { CampaignId } from 'components/Campaign';

export function creativeCreatePage(campaignId: number): string {
  return generatePath(CREATIVE_CREATE, { campaignId });
}

export function creativeShowPage(creative: CreativeObjectProps): string {
  return generatePath(CREATIVE_SHOW, {
    campaignId: creative.campaignId,
    creativeId: creative.id,
  });
}

export function creativeEditPage(creative: CreativeObjectProps): string {
  return generatePath(CREATIVE_EDIT, {
    campaignId: creative.campaignId,
    creativeId: creative.id,
  });
}

export const CreateCreativeLink = ({
  campaignId,
  children,
  ...props
}: CampaignId & ButtonProps): JSX.Element => (
  <Button {...props} to={creativeCreatePage(campaignId)} as={Link}>
    {children}
  </Button>
);

export const ShowCreativeLink = ({
  creativeId,
  children,
  ...props
}: CreativeIdProps & ButtonProps): JSX.Element => {
  const creative = useAppSelector(state => selectCreative(state, creativeId));
  return (
    <Button {...props} to={creativeShowPage(creative)} as={Link}>
      {children}
    </Button>
  );
};

export const EditCreativeLink = ({
  creativeId,
  children,
  ...props
}: CreativeIdProps & ButtonProps): JSX.Element => {
  const creative = useAppSelector(state => selectCreative(state, creativeId));
  return (
    <Button {...props} to={creativeEditPage(creative)} as={Link}>
      {children}
    </Button>
  );
};

export const ShowCurrentCreativeLink = withCurrentCreativeId(ShowCreativeLink);
export const EditCurrentCreativeLink = withCurrentCreativeId(EditCreativeLink);
